import React from "react";
import { Spinner } from "reactstrap";

const Loader = (props) => {
    const { classes = "", size = "md", color = "primary" } = props;
    return (
        <div
            className={`d-flex flex-column justify-content-center align-items-center w-100 ${classes}`}
        >
            <Spinner size={size} color={color} />
        </div>
    );
};

export default Loader;
