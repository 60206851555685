import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import sidebarConfig from "../../sidebarConfig";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {sidebarConfig.map((item, index) => {
                            const hasChildrens =
                                item.hasOwnProperty("children") && Array.isArray(item.children);
                            return item.type && item.type === "title" ? (
                                <li key={index} className="menu-title">
                                    {this.props.t(item.title)}
                                </li>
                            ) : (
                                <li key={index}>
                                    <Link
                                        to={item.url}
                                        className={`waves-effect ${
                                            hasChildrens ? "has-arrow" : ""
                                        }`}
                                    >
                                        <i className={`${item.icon}`}></i>
                                        <span>{this.props.t(item.title)}</span>
                                    </Link>
                                    {hasChildrens && (
                                        <ul className="sub-menu" aria-expanded="false">
                                            {item.children.map((childItem, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={childItem.url}>
                                                            {this.props.t(childItem.title)}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(SidebarContent));
