import { injectBaseConstantMethods } from "./BaseConstants";

const GENDER_TYPES = {
    MALE: 1,
    FEMALE: 2,
    OTHER: 3
};

const displayTextKeys = {
    [GENDER_TYPES.MALE]: "Male",
    [GENDER_TYPES.FEMALE]: "Female",
    [GENDER_TYPES.OTHER]: "Other"
};

const labelClass = {
    [GENDER_TYPES.MALE]: "info",
    [GENDER_TYPES.FEMALE]: "success",
    [GENDER_TYPES.OTHER]: "secondary"
};

export default injectBaseConstantMethods(GENDER_TYPES, displayTextKeys, labelClass);
