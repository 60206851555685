import React from "react";
import { toast } from "react-toastify";

import { Toaster } from "../components/Toaster";

const customToaster = () => {
    let toastId = null;
    return ({ type, title, onClick, options = {} }) => {
        if (!toast.isActive(toastId)) {
            toastId = toast(<Toaster type={type} title={title} onClick={onClick} />, {
                ...options,
                toastId: "taskfox-service-toast"
            });
        }
    };
};

export const toaster = customToaster();
