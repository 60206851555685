import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { DataTable } from "../../components/DataTable";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getCustomerQueryListing } from "../../store/actions";
import { CustomBadge } from "../../components/Badge";
import { UserMessageType, UserRoles, MessageStatus } from "../../constants";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtil";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { Button } from "../../components/Button";
import { Pagination } from "../../components/Pagination";

const USER_COLUMNS = {
    REPORTED_USER_NAME: "Name",
    QUICK_BLURB: "Quick Blurb",
    EMAIL: "Email",
    USER_TYPE: "User Type",
    STATUS: "Status",
    CREATED_AT: "Created At",
    CATEGORY: "Category",
    ACTION: "Action"
};

const CustomerQueryListing = (props) => {
    const { fetching, data, totalQueries } = props;
    const [page, setPage] = useState(1);

    const queryListingController = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        queryListingController.current = new AbortController();
        dispatch(getCustomerQueryListing({ Page: page }));

        return () => {
            if (queryListingController.current) {
                queryListingController.current.abort();
            }
        };
    }, [dispatch, page]);

    const handleViewDetails = (data) => {
        props.history.push(getRoute(APP_ROUTES.CUSTOMER_QUERY_FEEDBACK, { id: data.Id }));
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Dashboard")}
                        breadcrumbItem={props.t("Customer Inquiries")}
                    />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        config={[
                                            {
                                                title: USER_COLUMNS.REPORTED_USER_NAME,
                                                render: (data) => data?.User?.FullName || "-"
                                            },
                                            {
                                                title: USER_COLUMNS.QUICK_BLURB,
                                                render: (data) => data.QuickBlurb,
                                                withWrap: true
                                            },
                                            {
                                                title: USER_COLUMNS.EMAIL,
                                                render: (data) => data?.User?.Email || "-"
                                            },
                                            {
                                                title: USER_COLUMNS.USER_TYPE,
                                                render: (data) =>
                                                    data?.User?.Type ? (
                                                        <CustomBadge
                                                            color={UserRoles.getLabelClass(
                                                                data?.User?.Type
                                                            )}
                                                            classes={UserRoles.getLabelClass(
                                                                data?.User?.Type
                                                            )}
                                                        >
                                                            {props.t(
                                                                UserRoles.getDisplayTextKey(
                                                                    data?.User?.Type
                                                                )
                                                            )}
                                                        </CustomBadge>
                                                    ) : (
                                                        "-"
                                                    )
                                            },
                                            {
                                                title: USER_COLUMNS.STATUS,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={MessageStatus.getLabelClass(
                                                            data.Status || MessageStatus.ACTIVE
                                                        )}
                                                        classes={MessageStatus.getLabelClass(
                                                            data.Status || MessageStatus.ACTIVE
                                                        )}
                                                    >
                                                        {props.t(
                                                            MessageStatus.getDisplayTextKey(
                                                                data.Status || MessageStatus.ACTIVE
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.CREATED_AT,
                                                render: (data) =>
                                                    convertDateTime({
                                                        date: data.Date,
                                                        customFormat: dateTimeFormat.appDateFormat
                                                    })
                                            },
                                            {
                                                title: USER_COLUMNS.CATEGORY,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={UserMessageType.getLabelClass(
                                                            data.Type
                                                        )}
                                                        classes={UserMessageType.getLabelClass(
                                                            data.Type
                                                        )}
                                                    >
                                                        {UserMessageType.getDisplayTextKey(
                                                            data.Type
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.ACTION,
                                                render: (data) => (
                                                    <Button
                                                        onClick={() => handleViewDetails(data)}
                                                        className="btn-rounded"
                                                        size="sm"
                                                    >
                                                        View Details
                                                    </Button>
                                                )
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={totalQueries}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        fetching: state.customerQuery.fetching,
        data: state.customerQuery.list,
        totalQueries: state.customerQuery.totalQueries
    };
};

export default connect(mapStateToProps)(CustomerQueryListing);
