import React from "react";
import { Collapse } from "reactstrap";

import { FilterButton } from "../../components/FilterButton";
import { MultiSlider } from "../../components/MultiSlider";

const RangeFilter = (props) => {
    const { title, isOpen, min, max, minValue = 0, maxValue = 0, onChange } = props;

    const handleSliderChange = (val) => {
        const [minVal, maxVal] = val;
        if (onChange && typeof onChange === "function") {
            onChange(minVal, maxVal);
        }
    };

    const getRange = () => {
        if (minValue && maxValue) {
            return ` ${minValue} to ${maxValue}`;
        }
        return "";
    };

    return (
        <div className="mb-3">
            {title && (
                <div className="d-flex mb-4">
                    <FilterButton text={`${title}: ${getRange()}`} />
                </div>
            )}
            <Collapse isOpen={isOpen}>
                <MultiSlider min={min} max={max} onAfterChange={handleSliderChange} />
            </Collapse>
        </div>
    );
};

export default RangeFilter;
