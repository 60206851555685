export const UserWorkingSituation = {
    UNEMPLOYED: 1,
    FULL_TIME: 2,
    PART_TIME: 3
};

export const UserWorkingSituationLabels = {
    [UserWorkingSituation.UNEMPLOYED]: "Unemployed",
    [UserWorkingSituation.FULL_TIME]: "Full Time",
    [UserWorkingSituation.PART_TIME]: "Part Time"
};
