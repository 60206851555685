import React from "react";
import { Badge } from "reactstrap";

const CustomBadge = ({ color, classes = "", children }) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + classes} color={color} pill>
            {children}
        </Badge>
    );
};

export default CustomBadge;
