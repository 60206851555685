import { ME_SUCCESS, SET_TOKEN } from "../actions";

const initialState = {
    isAuthenticated: false,
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: {
                    ...state.user,
                    ...action.payload.User
                }
            };
        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token
                }
            };
        default:
            return state;
    }
};
