import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import Switch from "react-switch";

import { DataTable } from "../../components/DataTable";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getPlanListing, editPlan, removePlan } from "../../store/actions";
import { CustomBadge } from "../../components/Badge";
import { DiscountType, PaymentStatus, PlanCategory } from "../../constants";
import { getFormattedAmountWithCurrency } from "../../utils/commonUtils";
import { SearchHeader } from "../../components/SearchHeader";
import { SweetAlert } from "../../components/Alert";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

const USER_COLUMNS = {
    TITLE: "Title",
    CATEGORY: "Category",
    AMOUNT: "Amount",
    DISCOUNT_TYPE: "Discount Type",
    DISCOUNT: "Discount",
    STATUS: "Status",
    ACTION: "Action"
};

const PaymentPlanListing = (props) => {
    const { fetching, data } = props;

    const paymentListingController = useRef(null);
    const [showBlockUnBlockAlert, setShowBlockUnBlockeAlert] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        paymentListingController.current = new AbortController();

        dispatch(getPlanListing({}, paymentListingController.current.signal));

        return () => {
            if (paymentListingController.current) {
                paymentListingController.current.abort();
            }
        };
    }, [dispatch]);

    const getDiscounts = (item) => {
        if (item.Discount) {
            if (item.DiscountType === DiscountType.FIXED) {
                return (
                    <CustomBadge
                        color="success"
                        classes="success"
                    >{`${getFormattedAmountWithCurrency(
                        item.Discount,
                        item.Currency
                    )} off`}</CustomBadge>
                );
            } else if (item.DiscountType === DiscountType.PERCENTAGE) {
                return (
                    <CustomBadge color="info" classes="info">{`${
                        item.Discount * 100
                    }% off`}</CustomBadge>
                );
            }
        }
        return (
            <CustomBadge color="danger" classes="danger">
                No Discount
            </CustomBadge>
        );
    };

    const isPlanActive = (plan) => {
        if (plan.Status === PaymentStatus.ACTIVE) {
            return true;
        }
        return false;
    };

    const handleBlockUnBlockConfirmation = (data) => {
        setSelectedData(data);
        setShowBlockUnBlockeAlert(true);
    };

    const handleDeleteConfirmation = (data) => {
        setSelectedData(data);
        setShowDeleteConfirmation(true);
    };

    const onConfirmPress = () => {
        let status =
            selectedData.Status === PaymentStatus.ACTIVE
                ? PaymentStatus.INACTIVE
                : PaymentStatus.ACTIVE;
        dispatch(editPlan({ ...selectedData, Status: status }));
        setShowBlockUnBlockeAlert(false);
    };

    const onConfirmDeletePress = () => {
        dispatch(removePlan(selectedData.Id));
        setShowDeleteConfirmation(false);
    };

    const onCancelPress = () => {
        setShowBlockUnBlockeAlert(false);
        setShowDeleteConfirmation(false);
        setSelectedData(null);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Dashboard")}
                        breadcrumbItem={props.t("Payment Plans")}
                    />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <SearchHeader
                                        showButton={true}
                                        buttonText="Add New Plan"
                                        showSearchField={false}
                                        onButtonClick={() =>
                                            props.history.push(getRoute(APP_ROUTES.CREATE_PACKAGE))
                                        }
                                    />
                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        config={[
                                            {
                                                title: USER_COLUMNS.TITLE,
                                                render: (data) => data.Name
                                            },
                                            {
                                                title: USER_COLUMNS.CATEGORY,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={PlanCategory.getLabelClass(
                                                            data.Category
                                                        )}
                                                        classes={PlanCategory.getLabelClass(
                                                            data.Category
                                                        )}
                                                    >
                                                        {props.t(
                                                            PlanCategory.getDisplayTextKey(
                                                                data.Category
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.AMOUNT,
                                                render: (data) =>
                                                    getFormattedAmountWithCurrency(
                                                        data.Amount,
                                                        data.Currency
                                                    )
                                            },
                                            {
                                                title: USER_COLUMNS.DISCOUNT_TYPE,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={DiscountType.getLabelClass(
                                                            data.DiscountType
                                                        )}
                                                        classes={DiscountType.getLabelClass(
                                                            data.DiscountType
                                                        )}
                                                    >
                                                        {props.t(
                                                            DiscountType.getDisplayTextKey(
                                                                data.DiscountType
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.DISCOUNT,
                                                render: (data) => getDiscounts(data)
                                            },
                                            {
                                                title: USER_COLUMNS.STATUS,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={PaymentStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                        classes={PaymentStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                    >
                                                        {props.t(
                                                            PaymentStatus.getDisplayTextKey(
                                                                data.Status
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.ACTION,
                                                render: (data, index) => (
                                                    <ul className="list-inline font-size-20 contact-links mb-0 d-flex align-items-center">
                                                        <li className="list-inline-item px-2">
                                                            <Link
                                                                id={`edit${data.Id}`}
                                                                className="text-primary"
                                                                to={getRoute(
                                                                    APP_ROUTES.UPDATE_PACKAGE,
                                                                    { id: data.Id }
                                                                )}
                                                            >
                                                                <i className="mdi mdi-pencil"></i>
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={`edit${data.Id}`}
                                                                >
                                                                    {props.t("Edit")}
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item px-2">
                                                            <div
                                                                id={`delete${data.Id}`}
                                                                className="text-danger cursor-pointer"
                                                                onClick={() =>
                                                                    handleDeleteConfirmation(data)
                                                                }
                                                            >
                                                                <i className="mdi mdi-trash-can"></i>
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={`delete${data.Id}`}
                                                                >
                                                                    {props.t("Delete")}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item px-2">
                                                            <Switch
                                                                className="mt-1"
                                                                onColor="#846CE9"
                                                                height={20}
                                                                width={40}
                                                                checkedIcon={false}
                                                                uncheckedIcon={false}
                                                                onChange={() =>
                                                                    handleBlockUnBlockConfirmation(
                                                                        data
                                                                    )
                                                                }
                                                                checked={isPlanActive(data)}
                                                            />
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {showBlockUnBlockAlert && (
                    <SweetAlert
                        title={isPlanActive(selectedData) ? "Deactivate" : "Active"}
                        description={
                            isPlanActive(selectedData)
                                ? "Are you sure you want to deactivate this package?"
                                : "Are you sure you want to active this package?"
                        }
                        type={isPlanActive(selectedData) ? "danger" : "success"}
                        onConfirm={onConfirmPress}
                        onCancel={onCancelPress}
                        closeOnClickOutside={false}
                    />
                )}
                {showDeleteConfirmation && (
                    <SweetAlert
                        title="Delete"
                        description="Are you sure you want to delete this package?"
                        type="danger"
                        onConfirm={onConfirmDeletePress}
                        onCancel={onCancelPress}
                        closeOnClickOutside={false}
                    />
                )}
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        fetching: state.payment.fetching,
        data: state.payment.list
        // totalPendingAdvisors: state.advisor.totalPendingAdvisors
    };
};

export default connect(mapStateToProps)(PaymentPlanListing);
