import Config from "../../Config";

import {
    CUSTOMER_QUERY_REQUEST,
    CUSTOMER_QUERY_SUCCESS,
    CUSTOMER_QUERY_FAILED,
    QUERY_MESSAGE_REQUEST,
    QUERY_MESSAGE_SUCCESS,
    QUERY_MESSAGE_FAILED,
    ADD_NEW_MESSAGE,
    UPDATE_CUSTOMER_QUERY
} from "../actions";

const initialState = {
    fetching: false,
    list: [],
    totalQueries: 0,
    currentQueryChat: {
        details: {},
        fetching: false,
        dataAvailable: true,
        messages: [],
        totalMessages: 0
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_QUERY_REQUEST:
            return {
                ...state,
                fetching: true,
                list: []
            };
        case CUSTOMER_QUERY_SUCCESS:
            return {
                ...state,
                fetching: false,
                list: action.payload,
                totalQueries: action.totalQueries
            };
        case CUSTOMER_QUERY_FAILED:
            return {
                ...state,
                fetching: false
            };
        case QUERY_MESSAGE_REQUEST:
            return {
                ...state,
                currentQueryChat: {
                    ...state.currentQueryChat,
                    fetching: true,
                    messages: (() => {
                        if (!action.Page || action.Page === 1) {
                            return [];
                        }
                        return state.currentQueryChat.messages;
                    })()
                }
            };
        case QUERY_MESSAGE_SUCCESS:
            return {
                ...state,
                currentQueryChat: {
                    ...state.currentQueryChat,
                    details: action.details,
                    messages: [...action.payload, ...state.currentQueryChat.messages],
                    fetching: false,
                    dataAvailable: action.payload.length === Config.LIMIT
                }
            };
        case QUERY_MESSAGE_FAILED:
            return {
                ...state,
                currentQueryChat: {
                    ...state.currentQueryChat,
                    fetching: false
                }
            };
        case ADD_NEW_MESSAGE:
            return {
                ...state,
                currentQueryChat: {
                    ...state.currentQueryChat,
                    messages: [...state.currentQueryChat.messages, action.payload]
                }
            };
        case UPDATE_CUSTOMER_QUERY:
            return {
                ...state,
                currentQueryChat: {
                    ...state.currentQueryChat,
                    details: {
                        ...state.currentQueryChat.details,
                        ...action.payload
                    }
                }
            };
        default:
            return state;
    }
};
