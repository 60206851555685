import React, { Component } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { Button } from "../../components/Button";
import { login } from "../../store/actions";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { Roles } from "../../constants";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/hc-sm.png";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isLoading: false
        };

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleValidSubmit() {
        this.setState({ isLoading: true });
        this.props
            .login({ Email: this.state.email, Password: this.state.password, Type: Roles.ADMIN })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { t, isAuthenticated } = this.props;
        const { email, password } = this.state;

        if (isAuthenticated) {
            return <Redirect to={getRoute(APP_ROUTES.DASHBOARD)} />;
        }

        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">
                                                        {t("Welcome Back")}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="40"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                <div className="form-group">
                                                    <AvField
                                                        name="email"
                                                        label={t("Email")}
                                                        value={email}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        placeholder={t("Enter Email")}
                                                        errorMessage={t(
                                                            "Please fill in the required fields"
                                                        )}
                                                        validate={{
                                                            required: { value: true },
                                                            email: {
                                                                errorMessage: t(
                                                                    "Please enter a valid email ID"
                                                                )
                                                            },
                                                            maxLength: {
                                                                value: 128,
                                                                errorMessage:
                                                                    "Max 128 characters are allowed"
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <AvField
                                                        name="password"
                                                        label={this.props.t("Password")}
                                                        value={password}
                                                        onChange={this.handleChange}
                                                        placeholder={t("Enter Password")}
                                                        type="password"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    "Please fill in the required fields."
                                                            },
                                                            maxLength: {
                                                                value: 128,
                                                                errorMessage:
                                                                    "Max 128 characters are allowed"
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="mt-3">
                                                    <Button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        isLoading={this.state.isLoading}
                                                        type="submit"
                                                    >
                                                        {t("Log In")}
                                                    </Button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <Link
                                                        to={getRoute(APP_ROUTES.FORGOT_PASSWORD)}
                                                        className="text-muted"
                                                    >
                                                        <i className="mdi mdi-lock mr-1"></i>
                                                        {t("Forgot your password?")}
                                                    </Link>
                                                </div> */}
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
        isAuthenticated: state.userAuth.isAuthenticated
    };
};

const mapDispatchToProps = {
    login
};

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(withNamespaces()(Login)));
