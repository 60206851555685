export const UserLivingStatus = {
    WITH_FAMILY: 1,
    ALONE: 2,
    SHARED_ACCOMODATION: 3
};

export const UserLivingStatusLabels = {
    [UserLivingStatus.WITH_FAMILY]: "With Family",
    [UserLivingStatus.ALONE]: "Alone",
    [UserLivingStatus.SHARED_ACCOMODATION]: "Shared Accomodation"
};
