import {
    USER_LISTING_REQUEST,
    USER_LISTING_SUCCESS,
    USER_LISTING_FAILURE,
    USER_UPDATE_STATUS
} from "../actions";

const initialState = {
    list: [],
    fetching: false,
    totalUsers: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: []
            };

        case USER_LISTING_SUCCESS:
            return {
                ...state,
                list: action.payload,
                totalUsers: action.totalUsers,
                fetching: false
            };

        case USER_LISTING_FAILURE:
            return {
                ...state,
                list: [],
                fetching: false
            };

        case USER_UPDATE_STATUS:
            return {
                ...state,
                fetching: false,
                list: state.list.map((user) => {
                    if (user.UserId === action.payload) {
                        return {
                            ...user,
                            [action.key]: action.value
                        };
                    } else return user;
                })
            };

        default:
            return state;
    }
};
