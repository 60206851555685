import React from "react";
import { Col, Card, Media, CardBody } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { Spinner } from "reactstrap";

const Widget = (props) => {
    const { md = "4", isLoading = false, cardClasses = "", cardData } = props;
    return (
        <Col md={md}>
            <Card className={`mini-stats-wid ${cardClasses}`}>
                <CardBody className="widget-card">
                    <Media
                        className={
                            isLoading
                                ? "justify-content-center align-items-center widget-height"
                                : ""
                        }
                    >
                        {!isLoading ? (
                            <React.Fragment>
                                <Media body>{cardData}</Media>
                            </React.Fragment>
                        ) : (
                            <div>
                                <Spinner size="md" color="primary" />
                            </div>
                        )}
                    </Media>
                </CardBody>
            </Card>
        </Col>
    );
};

export default withNamespaces()(Widget);
