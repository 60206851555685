import { ENTITY_ADD_QUERIES, ENTITY_UPDATE_QUERIES } from "../actions";

const initialState = {
    queries: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ENTITY_ADD_QUERIES:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    ...action.payload
                }
            };
        case ENTITY_UPDATE_QUERIES:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.id]: {
                        ...state.queries[action.id],
                        ...action.data
                    }
                }
            };
        default:
            return state;
    }
};
