import React from "react";

import "./FilterButton.scss";

const FilterButton = (props) => {
    const { text, classes, onClick } = props;
    return (
        <p className={`mb-0 filter-btn ${classes}`} onClick={onClick}>
            {text}
        </p>
    );
};

export default FilterButton;
