import { injectBaseConstantMethods } from "./BaseConstants";

const UserRoles = {
    ADMIN: 1,
    MATCH_MAKER: 2,
    USER: 3,
    GUARDIAN: 4
};

const displayTextKeys = {
    [UserRoles.ADMIN]: "Admin",
    [UserRoles.MATCH_MAKER]: "Match maker",
    [UserRoles.USER]: "User",
    [UserRoles.GUARDIAN]: "Guardian"
};

const labelClass = {
    [UserRoles.ADMIN]: "success",
    [UserRoles.MATCH_MAKER]: "info",
    [UserRoles.USER]: "warning",
    [UserRoles.GUARDIAN]: "danger"
};

export default injectBaseConstantMethods(UserRoles, displayTextKeys, labelClass);
