import React from "react";

import "./UserListingBadge.scss";

const UserListingBadge = (props) => {
    return (
        <div
            className={`hc-user-listing-badge ${props.className && props.className}`}
            onClick={props?.onClick}
        >
            <img
                className="rounded-circle bg-color"
                src={props.src ? props.src : require("../../assets/images/anonymous.png")}
                alt={props.name}
                width="50"
                height="50"
            />
            <span className="hc-user-listing-badge-name p">{props?.name}</span>
        </div>
    );
};

export default UserListingBadge;
