export const UserEducationLevel = {
    HIGH_SCHOOL: 1,
    UNDER_GRADUATE: 2,
    POST_GRADUATE: 3
};

export const UserEducationLabels = {
    [UserEducationLevel.HIGH_SCHOOL]: "High School",
    [UserEducationLevel.UNDER_GRADUATE]: "Undergraduate",
    [UserEducationLevel.POST_GRADUATE]: "Postgraduate"
};
