import { injectBaseConstantMethods } from "./BaseConstants";

const USER_FILTER_TYPES = {
    ALL_USERS: 1,
    FREE_USERS: 2,
    PAID_USERS: 3,
    GUARDIAN: 4,
    TOTAL_ACTIVE_USERS: 5
};

const displayTextKeys = {
    [USER_FILTER_TYPES.ALL_USERS]: "All Users",
    [USER_FILTER_TYPES.FREE_USERS]: "Free Users",
    [USER_FILTER_TYPES.PAID_USERS]: "Paid Users",
    [USER_FILTER_TYPES.GUARDIAN]: "Guardian",
    [USER_FILTER_TYPES.TOTAL_ACTIVE_USERS]: "Total Active Users"
};

const labelClass = {
    [USER_FILTER_TYPES.ALL_USERS]: "success",
    [USER_FILTER_TYPES.FREE_USERS]: "success",
    [USER_FILTER_TYPES.PAID_USERS]: "success",
    [USER_FILTER_TYPES.GUARDIAN]: "success",
    [USER_FILTER_TYPES.TOTAL_ACTIVE_USERS]: "success"
};

export default injectBaseConstantMethods(USER_FILTER_TYPES, displayTextKeys, labelClass);
