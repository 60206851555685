import { injectBaseConstantMethods } from "./BaseConstants";

const RESOLVER_TYPES = {
    ADMIN: 1,
    MATCH_MAKER: 2
};

const displayTextKeys = {
    [RESOLVER_TYPES.ADMIN]: "Admin",
    [RESOLVER_TYPES.MATCH_MAKER]: "Match Maker"
};

const labelClass = {
    [RESOLVER_TYPES.ADMIN]: "info",
    [RESOLVER_TYPES.MATCH_MAKER]: "info"
};

export default injectBaseConstantMethods(RESOLVER_TYPES, displayTextKeys, labelClass);
