import Config from "../../Config";
import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import { UserStatus } from "../../constants/UserStatus";

export const USER_LISTING_REQUEST = "USER_LISTING_REQUEST";
export const USER_LISTING_SUCCESS = "USER_LISTING_SUCCESS";
export const USER_LISTING_FAILURE = "USER_LISTING_FAILURE";
export const USER_UPDATE_STATUS = "USER_UPDATE_STATUS";
export const USER_UPDATE_GUARDIAN = "USER_UPDATE_GUARDIAN";

export const getUserListing = (params = {}, abortSignal = null, canDispatch = true) => (
    dispatch,
    getState
) => {
    if (canDispatch) {
        dispatch({ type: USER_LISTING_REQUEST });
    }

    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/admin-user-list", { Limit: Config.LIMIT, ...params }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                if (canDispatch) {
                    dispatch({
                        type: USER_LISTING_SUCCESS,
                        payload: res.Data.Details,
                        totalUsers: res.Data.Count
                    });
                }

                return Promise.resolve(res);
            }
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({
                type: USER_LISTING_FAILURE
            });
            return Promise.reject(err);
        });
};

export const getGuardianListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    dispatch({ type: USER_LISTING_REQUEST });

    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/guardian/list", { Limit: Config.LIMIT, ...params }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: USER_LISTING_SUCCESS,
                    payload: res.Data.Details,
                    totalUsers: res.Data.Count
                });
                return Promise.resolve(res);
            }
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({
                type: USER_LISTING_FAILURE
            });
            return Promise.reject(err);
        });
};

export const getMatchMakerListing = (params = {}, abortSignal) => (dispatch, getState) => {
    dispatch({ type: USER_LISTING_REQUEST });

    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/matchmaker/list", { Limit: Config.LIMIT, ...params }),
        token,
        abortSignal
    })
        .then((res) => {
            dispatch({
                type: USER_LISTING_SUCCESS,
                payload: res.Data.Details,
                totalUsers: res.Data.Count
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({
                type: USER_LISTING_FAILURE
            });
            return Promise.reject(err);
        });
};

export const getUserById = (id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/details", { UserId: id }),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getGuardianById = (id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: `/user/guardian/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMatchMakerById = (id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: `/user/matchmaker/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const removeUser = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/remove",
        token,
        method: "POST",
        body: JSON.stringify(payload)
    })
        .then((res) => {
            dispatch({
                type: USER_UPDATE_STATUS,
                payload: payload.UserId,
                key: "Status",
                value: UserStatus.MANUAL_DELETED
            });

            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const userRestore = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/restore",
        token,
        method: "POST",
        body: JSON.stringify(payload)
    })
        .then(async (res) => {
            const { Data } = await dispatch(getUserById(payload.UserId));

            dispatch({
                type: USER_UPDATE_STATUS,
                payload: Data.UserId,
                key: "Status",
                value: Data.Status
            });

            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const guardianRestore = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/restore",
        token,
        method: "POST",
        body: JSON.stringify(payload)
    })
        .then(async (res) => {
            const { Data } = await dispatch(getGuardianById(payload.UserId));

            dispatch({
                type: USER_UPDATE_STATUS,
                payload: Data.UserId,
                key: "Status",
                value: Data.Status
            });

            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const matchMakerRestore = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/restore",
        token,
        method: "POST",
        body: JSON.stringify(payload)
    })
        .then(async (res) => {
            const { Data } = await dispatch(getMatchMakerById(payload.UserId));

            dispatch({
                type: USER_UPDATE_STATUS,
                payload: Data.UserId,
                key: "Status",
                value: Data.Status
            });

            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const createUser = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/add",
        method: "POST",
        body: JSON.stringify(payload),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const inviteMatchMaker = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/user/matchmaker/invite",
        method: "POST",
        body: JSON.stringify(payload),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const assignGuardianToUser = (payload, userId) => (dispatch, getState) => {
    return dispatch(createUser(payload))
        .then((res) => {
            dispatch({
                type: USER_UPDATE_STATUS,
                payload: userId,
                key: "WithGuardian",
                value: true
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateUser = (payload, id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: `/user/update/${id}`,
        method: "PUT",
        body: JSON.stringify(payload),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
