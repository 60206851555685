import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Input, CardBody } from "reactstrap";
import { useDispatch, connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import "./CustomerQueryFeedback.scss";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button } from "../../components/Button";
import { getUserContactChat, addMessage, replyOnContactUs } from "../../store/actions";
import { Loader } from "../../components/Loader";
import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { Roles, MessageStatus, UserMessageType } from "../../constants";
import { scrollToBottom } from "../../utils/domUtils";
import { CustomBadge } from "../../components/Badge";

const CustomerQueryFeedback = (props) => {
    const {
        currentQueryChat: { details, messages }
    } = props;
    const dispatch = useDispatch();
    const [reply, setReply] = useState("");
    const [fetching, setFetching] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        dispatch(getUserContactChat(id)).finally(() => {
            setFetching(false);
            scrollToBottom();
        });
    }, [dispatch, id]);

    const handleOnChange = (evt) => {
        setReply(evt.target.value);
    };

    const renderUserMessage = (data, index) => {
        return (
            <div key={index} className="pl-3 pr-3">
                <div className="p-2 user-reply-container float-left user-reply">
                    <div className="d-flex align-items-center ">
                        <div>
                            <img
                                className="rounded-circle avatar-sm avatar-container"
                                src={data.SenderPicture}
                                alt=""
                            />
                        </div>
                        <div className="ml-2">
                            <p className="font-weight-bold">{data.SenderName}</p>
                            <p className="msg-max-width">{data.Message}</p>
                        </div>
                    </div>
                    <p className="ml-2 mt-1">{moment(data.DateTime).format("DD-MM-YYYY")}</p>
                </div>
            </div>
        );
    };

    const renderAdminMessage = (data, index) => {
        return (
            <div key={index} className="pl-3 pr-3 mt-3 mb-3">
                <div className="float-right p-2 admin-reply-container">
                    <div className="d-flex align-items-center">
                        <p className="text-right msg-max-width mr-2">{data.Message}</p>
                        <img
                            className="rounded-circle avatar-sm avatar-container"
                            src={require("../../assets/images/anonymous.png")}
                            alt=""
                        />
                    </div>
                    <p className="float-right mt-1">{moment(data.DateTime).format("DD-MM-YYYY")}</p>
                </div>
            </div>
        );
    };

    const renderMessage = (data, index) => {
        if (data.SenderType === Roles.USER) {
            return renderUserMessage(data, index);
        }
        return renderAdminMessage(data, index);
    };

    const handleMessageSent = async () => {
        const addMsgData = {
            Message: reply,
            DateTime: Number(moment().format("x")),
            SenderType: Roles.ADMIN
        };
        setIsLoading(true);
        try {
            await dispatch(replyOnContactUs({ ContactId: id, Content: reply }));
            dispatch(addMessage(addMsgData));
            setReply("");
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return !fetching && Object.keys(details).length ? (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Dashboard" breadcrumbItem={`Customer Query # ${id}`} />
                <div className="feedback-details">
                    <p>
                        Status:{" "}
                        <CustomBadge
                            color={MessageStatus.getLabelClass(
                                details.Status || MessageStatus.ACTIVE
                            )}
                            classes={MessageStatus.getLabelClass(
                                details.Status || MessageStatus.ACTIVE
                            )}
                        >
                            {MessageStatus.getDisplayTextKey(
                                details.Status || MessageStatus.ACTIVE
                            )}
                        </CustomBadge>
                    </p>
                    <p>Date: {moment(details?.Date).format("DD-MM-YYYY")}</p>
                    <p>Reported By: {details?.User?.FullName}</p>
                    <p>
                        Category:{" "}
                        {
                            <CustomBadge
                                color={UserMessageType.getLabelClass(details.Type)}
                                classes={UserMessageType.getLabelClass(details.Type)}
                            >
                                {UserMessageType.getDisplayTextKey(details.Type)}
                            </CustomBadge>
                        }
                    </p>
                </div>
                <Row>
                    <Col md="7">
                        <Card className="mt-3">
                            <CardBody>
                                <div>
                                    <h4 className="font-weight-bold">Details</h4>
                                    <p>{details?.QuickBlurb || "Not available"}</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card
                            id="chatbox-scroll-div"
                            className="chat-main-container fancy-scroll auto"
                        >
                            <p className="p-3 text-center font-weight-bold chat-message-section"></p>
                            {messages && messages.length
                                ? messages.map((message, index) => {
                                      return renderMessage(message, index);
                                  })
                                : null}
                            {!messages?.length && (
                                <NoDataPlaceholder
                                    classes="no-message-container"
                                    text="No message available"
                                />
                            )}
                            <div className="p-2 pl-3 pr-3 text-message-container">
                                <p className="font-weight-bold mb-2">
                                    Type here to send your response.
                                </p>
                                <div className="form-group">
                                    <Input
                                        type="textarea"
                                        className="resize-textBox"
                                        value={reply}
                                        onChange={handleOnChange}
                                        rows="5"
                                    />
                                </div>
                                <Button
                                    disabled={!reply.trim().length}
                                    onClick={handleMessageSent}
                                    className="float-right"
                                    isLoading={isLoading}
                                >
                                    Send
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    ) : !fetching && !Object.keys(details).length ? (
        <NoDataPlaceholder />
    ) : (
        <Loader classes="vh-100" />
    );
};

const mapStateToProps = (state) => {
    return {
        currentQueryChat: state.customerQuery.currentQueryChat
    };
};

export default connect(mapStateToProps, null)(CustomerQueryFeedback);
