import React from "react";
import { withNamespaces } from "react-i18next";
import { CardBody, CardHeader, Collapse, Card } from "reactstrap";

import "./Accordian.scss";

const Accordion = ({
    onClick = (e) => {},
    containerClasses = "",
    isOpen = false,
    title = "",
    children
}) => {
    return (
        <Card className={"mb-1 " + containerClasses}>
            <CardHeader className="p-3 cursor-pointer accordian-container" id="headingTwo" onClick={onClick}>
                <h6 className="m-0 font-14 d-flex justify-content-between">
                    <span style={{ cursor: "pointer", color: "#fff" }} className="text-light">
                        {title}
                    </span>
                    <span className="align-self-end">
                        {isOpen ? (
                            <i className="bx bx-chevron-up chevron-up-custom text-light"></i>
                        ) : (
                            <i className="bx bx-chevron-down chevron-up-custom text-light"></i>
                        )}
                    </span>
                </h6>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>{children}</CardBody>
            </Collapse>
        </Card>
    );
};

export default withNamespaces()(Accordion);
