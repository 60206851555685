import { injectBaseConstantMethods } from "./BaseConstants";

const PaymentStatus = {
    INACTIVE: 0,
    ACTIVE: 1
};

const displayTextKeys = {
    [PaymentStatus.INACTIVE]: "Inactive",
    [PaymentStatus.ACTIVE]: "Active"
};

const labelClass = {
    [PaymentStatus.INACTIVE]: "danger",
    [PaymentStatus.ACTIVE]: "success"
};

export default injectBaseConstantMethods(PaymentStatus, displayTextKeys, labelClass);
