import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, FormGroup, Label, Button } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import "bootstrap-daterangepicker/daterangepicker.css";
import "./RangePicker.scss";
import { FilterButton } from "../FilterButton";
import { Checkbox } from "../../components/Checkbox";

const RANGE_TYPES = {
    CURRENT: 1,
    PAST: 2
};

const RangePicker = (props) => {
    const { value, onChange } = props;
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: moment(),
        endDate: moment()
    });
    const [pastDateRange, setPastDateRange] = useState({
        startDate: moment(),
        endDate: moment()
    });
    const [enableCompare, setEnableCompare] = useState(false);
    const [selectedRange, setSelectedRange] = useState(RANGE_TYPES.CURRENT);
    const calendarRef = useRef(null);

    useEffect(() => {
        if (open) {
            setDateRange({
                startDate: value?.startDate || moment(),
                endDate: value?.endDate || moment()
            });

            setPastDateRange({
                startDate: value?.comparedStartDate || moment(),
                endDate: value?.comparedEndDate || moment()
            });
            calendarRef.current.setStartDate(value?.startDate || moment());
            calendarRef.current.setEndDate(value?.endDate || moment());

            let el = document.getElementById("date-range");
            if (el) {
                el.click();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (calendarRef.current) {
            let currentDate = dateRange;
            let elementId = "date-range";
            if (selectedRange === RANGE_TYPES.PAST) {
                currentDate = pastDateRange;
                elementId = "past-date-range";
            }
            let el = document.getElementById(elementId);
            const { startDate, endDate } = currentDate;
            calendarRef.current.setStartDate(startDate);
            calendarRef.current.setEndDate(endDate);
            el.click();
            el.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRange]);

    const handleMenuToggle = (e) => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDateRangeChange = (event, picker) => {
        const { startDate, endDate } = picker;
        if (selectedRange === RANGE_TYPES.PAST) {
            setPastDateRange({ startDate, endDate });
        } else {
            setDateRange({ startDate, endDate });
        }
    };

    const getCurrentDateObj = () => {
        if (selectedRange === RANGE_TYPES.PAST) {
            return pastDateRange;
        }
        return dateRange;
    };

    const handleCompareToPastChange = (e) => {
        let checked = e.target.checked;
        if (!checked) {
            setSelectedRange(RANGE_TYPES.CURRENT);
        }
        setEnableCompare(checked);
    };

    const handleApplyClick = () => {
        if (onChange && typeof onChange === "function") {
            onChange({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                comparedStartDate: enableCompare ? pastDateRange.startDate : null,
                comparedEndDate: enableCompare ? pastDateRange.endDate : null
            });
        }
        setOpen(false);
    };

    const handleCancelClick = () => {
        setOpen(false);
        setDateRange({
            startDate: moment(),
            endDate: moment()
        });
        setPastDateRange({
            startDate: moment(),
            endDate: moment()
        });
        setEnableCompare(false);
        if (onChange && typeof onChange === "function") {
            onChange({
                startDate: null,
                endDate: null,
                comparedStartDate: null,
                comparedEndDate: null
            });
        }
    };

    return (
        <Dropdown isOpen={open} toggle={handleMenuToggle} direction="left">
            <DropdownToggle tag="div" className=" data-table-head sortable">
                <FilterButton text="Time Range" classes="mr-3 font-weight-bold" />
            </DropdownToggle>
            <DropdownMenu className="custom-filter-body filter-menu">
                <div className="picker-container">
                    <DateRangePicker
                        ref={calendarRef}
                        onApply={handleDateRangeChange}
                        initialSettings={{
                            startDate: getCurrentDateObj().startDate,
                            endDate: getCurrentDateObj().endDate,
                            parentEl: ".picker-container",
                            opens: "left",
                            autoApply: true
                        }}
                    >
                        <div className="picker-right-content">
                            <div>
                                <FormGroup>
                                    <Label>Date Range</Label>
                                    <input
                                        id="date-range"
                                        readOnly={true}
                                        type="text"
                                        onClick={() => setSelectedRange(RANGE_TYPES.CURRENT)}
                                        className={`form-control ${
                                            selectedRange === RANGE_TYPES.CURRENT ? "selected" : ""
                                        }`}
                                        value={`${dateRange.startDate.format(
                                            "YYYY-MM-DD"
                                        )} - ${dateRange.endDate.format("YYYY-MM-DD")}`}
                                    />
                                </FormGroup>
                                <FormGroup className="my-2">
                                    <Checkbox
                                        title="Compare to"
                                        checked={enableCompare}
                                        onChange={handleCompareToPastChange}
                                        id="compare-range"
                                    />
                                </FormGroup>
                                {enableCompare && (
                                    <FormGroup>
                                        <Label>Date Range</Label>
                                        <input
                                            id="past-date-range"
                                            readOnly={true}
                                            type="text"
                                            onClick={() => setSelectedRange(RANGE_TYPES.PAST)}
                                            className={`form-control ${
                                                selectedRange === RANGE_TYPES.PAST ? "selected" : ""
                                            }`}
                                            value={`${pastDateRange.startDate.format(
                                                "YYYY-MM-DD"
                                            )} - ${pastDateRange.endDate.format("YYYY-MM-DD")}`}
                                        />
                                    </FormGroup>
                                )}
                            </div>
                            <div className="mb-2">
                                <Button
                                    color="success"
                                    className="mr-2"
                                    size="sm"
                                    onClick={handleApplyClick}
                                >
                                    Apply
                                </Button>
                                <Button color="danger" size="sm" onClick={handleCancelClick}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </DateRangePicker>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default RangePicker;
