import { injectBaseConstantMethods } from "./BaseConstants";

const REPORT_ACTION = {
    WARNING: 1,
    SUSPEND: 2,
    REMOVE: 3,
    RESOLVED: 4
};

const displayTextKeys = {
    [REPORT_ACTION.WARNING]: "Warning",
    [REPORT_ACTION.SUSPEND]: "Suspend",
    [REPORT_ACTION.REMOVE]: "Remove",
    [REPORT_ACTION.RESOLVED]: "Resolved"
};

const labelClass = {
    [REPORT_ACTION.WARNING]: "info",
    [REPORT_ACTION.SUSPEND]: "info",
    [REPORT_ACTION.REMOVE]: "info",
    [REPORT_ACTION.RESOLVED]: "info"
};

export default injectBaseConstantMethods(REPORT_ACTION, displayTextKeys, labelClass);
