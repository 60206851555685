import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import Config from "../../Config";

export const REPORT_LISTING_REQUEST = "REPORT_LISTING_REQUEST";
export const REPORT_LISTING_SUCCESS = "REPORT_LISTING_SUCCESS";
export const REPORT_LISTING_FAIL = "REPORT_LISTING_FAIL";

export const getReportsListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    dispatch({ type: REPORT_LISTING_REQUEST });

    return fetchUtil({
        url: appendQueryParams("/report/list", { Limit: Config.LIMIT, ...params }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: REPORT_LISTING_SUCCESS,
                    payload: res.Data.Reports,
                    count: res.Data.Count
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: REPORT_LISTING_FAIL });
            return Promise.reject(err);
        });
};

export const getReportDetails = (data) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    return fetchUtil({
        url: `/report/details/${data}`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMessageListing = (id, params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams(`/chat/messages/${id}`, {
            Limit: Config.LIMIT,
            Column: "CreatedAt",
            Direction: "DESC",
            ...params
        }),
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendReportAction = (data) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: `/report/action`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sentAppealAction = (data) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: `/report/appeal/action`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
