import React from "react";
import { Modal } from "reactstrap";

const UserListingModal = ({ body, headerTitle, handleClose, showHeader = true, isOpen }) => {
    return (
        <Modal isOpen={isOpen} centered={true}>
            {showHeader && (
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {headerTitle}
                    </h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}
            {body}
        </Modal>
    );
};

export default UserListingModal;
