import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import routes from "../../routes";
import { Loader } from "../../components/Loader";
import { Page500 } from "../../components/Utility";
import { getMe } from "../../store/actions";

const DefaultLayout = (props) => {
    const { Layout, isAuthenticated, tokenVerified, isLoading, getMe } = props;

    useEffect(() => {
        if (isAuthenticated && !tokenVerified) {
            getMe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    return tokenVerified ? (
        <Switch>
            {routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                            <Layout>
                                <route.component {...props} />
                            </Layout>
                        )}
                    />
                );
            })}
            <Redirect to="/dashboard" />
        </Switch>
    ) : !tokenVerified && isLoading ? (
        <Loader classes="vh-100" showVerifyText={true} />
    ) : (
        <Page500 />
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userAuth.isAuthenticated,
        isLoading: state.root.isLoading,
        tokenVerified: state.root.tokenVerified,
        currentUser: state.userAuth.user
    };
};

const mapDispatchToProps = {
    getMe
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
