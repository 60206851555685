import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch, connect } from "react-redux";
import { useParams } from "react-router-dom";

import { DiscountType, PaymentStatus, PlanCategory } from "../../constants";
import { Button } from "../../components/Button";
import { createPricingPlan, getPaymentPlanById, editPlan } from "../../store/actions";
import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

const PaymentPlanForm = (props) => {
    const { paymentPlan } = props;
    const { id } = useParams();
    const dispatch = useDispatch();

    const getPaymentPlan = (payment) => {
        return {
            title: payment?.Name || "",
            category: payment?.Category || PlanCategory.MONTHLY,
            paymentStatus: payment?.Status || PaymentStatus.ACTIVE,
            discount:
                payment?.DiscountType === DiscountType.PERCENTAGE
                    ? payment?.Discount * 100
                    : payment?.Discount || "",
            amount: payment?.Amount || "",
            discountType: payment?.DiscountType || DiscountType.FIXED,
            description: payment?.Description || ""
        };
    };

    const [fetching, setFetching] = useState(false);
    const [showError, setShowError] = useState(false);
    const [planState, setPlanState] = useState({
        ...getPaymentPlan(paymentPlan)
    });

    useEffect(() => {
        if (id && !paymentPlan) {
            dispatch(getPaymentPlanById(id))
                .then((res) => {
                    setPlanState(getPaymentPlan(res));
                })
                .catch((err) => {
                    setShowError(true);
                });
        }
    }, [dispatch, id, paymentPlan]);

    const getDiscountedAmount = (showWithDecimal = true) => {
        if (Number(planState.discountType) === DiscountType.FIXED) {
            return Number(planState.discount);
        }
        if (showWithDecimal) {
            return Number(planState.discount / 100);
        }
        return Number(planState.discount * 100);
    };

    const handleChange = (evt, type) => {
        let value = evt.target.value;
        let name = evt.target.name;
        if (Number(type) === DiscountType.PERCENTAGE) {
            if (name === "discount" && Number(evt.target.value <= 100)) {
                updateState(name, value);
            } else if (name === "amount") {
                updateState(name, value);
            }
        } else if (Number(type) === DiscountType.FIXED) {
            if (
                planState.amount &&
                name === "discount" &&
                Number(planState.amount) >= Number(value)
            ) {
                updateState(name, value);
                return;
            }
            if (name === "amount") {
                if (planState.discount && Number(planState.discount) > Number(value)) {
                    updateState("discount", "");
                }
                updateState(name, value);
            }
        } else if (!type) {
            updateState(name, value);
        }
    };

    const updateState = (key, value) => {
        setPlanState((plan) => {
            return {
                ...plan,
                [key]: value
            };
        });
    };

    const handleDiscountTypeSelect = (evt) => {
        setPlanState((plan) => {
            return {
                ...plan,
                [evt.target.name]: evt.target.value,
                discount: ""
            };
        });
    };

    const handleAddPlan = () => {
        setFetching(true);
        let body = {
            Name: planState.title,
            Category: planState.category,
            Status: planState.paymentStatus,
            Discount: getDiscountedAmount(),
            Amount: Number(planState.amount),
            DiscountType: planState.discountType,
            Description: planState.description
        };
        if (id) {
            dispatch(editPlan({ ...body, Id: id }))
                .then((res) => {
                    props.history.replace(getRoute(APP_ROUTES.PAYMENT_LISTING));
                })
                .catch((err) => {
                    setFetching(false);
                });
            return;
        }
        dispatch(createPricingPlan(body))
            .then((res) => {
                props.history.replace(getRoute(APP_ROUTES.PAYMENT_LISTING));
            })
            .catch((err) => {
                setFetching(false);
            });
    };

    return !showError ? (
        <div className="page-content">
            <Container fluid>
                <Row className="d-flex justify-content-center ">
                    <Col xs="7">
                        <Card className="mt-5">
                            <CardBody>
                                <h5 className="mb-4">
                                    {id ? "Update Payment Plan" : "Add Payment Plan"}
                                </h5>
                                <AvForm className="form-horizontal" onValidSubmit={handleAddPlan}>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField
                                                    name="title"
                                                    label="Title"
                                                    value={planState.title}
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Title is required."
                                                        },
                                                        maxLength: {
                                                            value: 35,
                                                            errorMessage:
                                                                "Title should be of maximum 35 characters."
                                                        }
                                                    }}
                                                    onChange={(evt) => handleChange(evt)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField
                                                    type="select"
                                                    name="category"
                                                    label="Category"
                                                    value={planState.category}
                                                    onChange={(evt) => handleChange(evt)}
                                                >
                                                    <option value={PlanCategory.MONTHLY}>
                                                        Monthly
                                                    </option>
                                                    <option value={PlanCategory.QUARTERLY}>
                                                        Quaterly
                                                    </option>
                                                    <option value={PlanCategory.YEARLY}>
                                                        Yearly
                                                    </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField
                                                    type="select"
                                                    name="discountType"
                                                    label="Discount Type"
                                                    value={planState.discountType}
                                                    onChange={handleDiscountTypeSelect}
                                                >
                                                    <option value={DiscountType.FIXED}>
                                                        Fixed
                                                    </option>
                                                    <option value={DiscountType.PERCENTAGE}>
                                                        Percentage
                                                    </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField
                                                    name="amount"
                                                    label="Amount"
                                                    value={planState.amount.toString()}
                                                    className="form-control"
                                                    placeholder="Enter amount"
                                                    onChange={(evt) =>
                                                        handleChange(evt, planState.discountType)
                                                    }
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Amount is required"
                                                        },
                                                        pattern: {
                                                            value: "^[0-9]+([.][0-9]+)?$",
                                                            errorMessage: "Only number is allowed."
                                                        },
                                                        maxLength: {
                                                            value: 35,
                                                            errorMessage:
                                                                "Amount should be of maximum 35 characters."
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Discount</label>
                                                <input
                                                    type="number"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    name="discount"
                                                    placeholder="Enter discount"
                                                    onChange={(evt) =>
                                                        handleChange(evt, planState.discountType)
                                                    }
                                                    value={planState.discount}
                                                    min="0"
                                                ></input>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <AvField
                                                    type="select"
                                                    name="paymentStatus"
                                                    label="Status"
                                                    value={planState.paymentStatus}
                                                    onChange={(evt) => handleChange(evt)}
                                                >
                                                    <option value={PaymentStatus.INACTIVE}>
                                                        Inactive
                                                    </option>
                                                    <option value={PaymentStatus.ACTIVE}>
                                                        Active
                                                    </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <p className="font-weight-bold mb-2">Description</p>
                                            <div className="form-group">
                                                <AvField
                                                    type="textarea"
                                                    name="description"
                                                    value={planState.description}
                                                    rows="4" 
                                                    className="resize-textBox"
                                                    placeholder="Enter description here..."
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Description is required"
                                                        }
                                                    }}
                                                    onChange={(evt) => handleChange(evt)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <Button type="submit" isLoading={fetching}>
                                                    {id ? "Update" : "Add"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    ) : (
        <NoDataPlaceholder />
    );
};

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    let paymentPlan;
    if (id) {
        paymentPlan = state.payment.list.find((payment) => payment.Id === Number(id));
    }
    return {
        paymentPlan
    };
};

export default connect(mapStateToProps, null)(PaymentPlanForm);
