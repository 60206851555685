import React from "react";

import "./NoDataPlaceholder.css";

const NoDataPlaceholder = ({ text = "No data available", classes = "" }) => {
    return (
        <div className={`no-data-container ${classes}`}>
            <p>{text}</p>
        </div>
    );
};

export default NoDataPlaceholder;
