import React from "react";

const Checkbox = (props) => {
    const { id, title, checked, onChange } = props;
    return (
        <div className="custom-control custom-checkbox mb-3">
            <input
                type="checkbox"
                className="custom-control-input"
                id={id}
                onChange={onChange}
                checked={checked}
            />
            <label className="custom-control-label" htmlFor={id}>
                {title}
            </label>
        </div>
    );
};

export default Checkbox;
