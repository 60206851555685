import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    ButtonDropdown,
    Card,
    CardBody
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import "./ReportDetails.scss";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Loader } from "../../components/Loader";
import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import {
    getReportDetails,
    getMessageListing,
    sendReportAction,
    sentAppealAction
} from "../../store/actions";
import { getReportStatus, getCauseType, getAppealCause } from "../../utils/appUtils";
import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import RecordedChat from "./RecordedChat";
import { scrollToBottom } from "../../utils/domUtils";
import { Roles, ReportStatus, UserStatus, ReportActions, AppealType } from "../../constants";
import PopUpModal from "./PopUpModal";
import ModalContent from "./ModalContent";
import { toaster } from "../../utils/toasterUtil";
import { SweetAlert } from "../../components/Alert";
import { CustomBadge } from "../../components/Badge";

const ReportDetails = () => {
    const [fetching, setFetching] = useState(true);
    const [reportDetails, setReportDetails] = useState({});
    const [singleButton, setSingleButton] = useState(false);
    const [page, setPage] = useState(1);
    const [messageListing, setMessageListing] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [messageFetching, setMessageFetching] = useState(true);
    const [totalMessageCount, setTotalMessageCount] = useState(0);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reportAction, setReportAction] = useState(null);
    const [userModal, setUserModal] = useState(false);
    const [showRemovalModal, setShowRemovalModal] = useState(false);
    const [isRemoveSelected, setIsRemoveSelected] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState({ isOpen: false, message: "" });
    const [showReportResolvedConfirmation, setShowReportResolvedConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        async function getReporting() {
            try {
                let res = await dispatch(getReportDetails(id));
                setFetching(false);
                setReportDetails(res.Data);
                dispatch(getMessageListing(res.Data.ChatId, { Page: page }))
                    .then((res) => {
                        setMessageListing(res.Data?.ChatMessages.reverse().concat(messageListing));
                        setParticipants(res.Data?.Users);
                        setTotalMessageCount(res.Data?.Count);
                        setMessageFetching(false);
                        scrollToBottom();
                    })
                    .catch((err) => {
                        setMessageFetching(false);
                    });
                setReportAction({ ...reportAction, ReportId: Number(id) });
            } catch (err) {
                setFetching(false);
            }
        }
        getReporting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    const openUserModal = (reportType) => {
        setReportAction({ ...reportAction, Type: reportType });
        setUserModal(true);
    };

    const submitAppealAction = (Data) => {
        dispatch(sentAppealAction(Data))
            .then((res) => {
                setReportDetails(res.Data);
                switch (Data.Type) {
                    case AppealType.END_SUSPENSION:
                        setShowSuccessMsg({
                            isOpen: true,
                            message: "Suspension period ended"
                        });
                        break;
                    case AppealType.CONTINUE_SUSPENSION:
                        setShowSuccessMsg({
                            isOpen: true,
                            message: "Suspension period will continue"
                        });
                        break;
                    case AppealType.RESUME_PROFILE:
                        setShowSuccessMsg({
                            isOpen: true,
                            message: "User will be reactive"
                        });
                        break;
                    case AppealType.APPEAL_DECLINE:
                        setShowSuccessMsg({
                            isOpen: true,
                            message: "Continue Suspension"
                        });
                        break;
                    default:
                }
            })
            .catch((err) => {});
    };

    const reportActions = [
        {
            name: "Warning",
            clickFunc: () => {
                setIsRemoveSelected(false);
                openUserModal(ReportActions.WARNING);
            }
        },
        {
            name: "Suspension",
            clickFunc: () => {
                setIsRemoveSelected(false);
                openUserModal(ReportActions.SUSPEND);
            }
        },
        {
            name: "Remove",
            clickFunc: () => {
                setIsRemoveSelected(false);
                openUserModal(ReportActions.REMOVE);
            }
        },
        {
            name: "Resolved",
            clickFunc: () => {
                setReportAction({ ...reportAction, Type: ReportActions.RESOLVED });
                setShowReportResolvedConfirmation(true);
            }
        }
    ];

    const getAppealActions = (type) => {
        let appealAction;
        switch (type) {
            case UserStatus.SUSPENDED:
                appealAction = [
                    {
                        name: "End Suspension",
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealType.END_SUSPENSION
                            });
                        }
                    },

                    {
                        name: "Continue Suspension",
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealType.CONTINUE_SUSPENSION
                            });
                        }
                    }
                ];
                break;
            case UserStatus.REMOVED:
                appealAction = [
                    {
                        name: "Resume profile",
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealType.RESUME_PROFILE
                            });
                        }
                    },
                    {
                        name: "Appeal decline",
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealType.APPEAL_DECLINE
                            });
                        }
                    }
                ];
                break;
            default:
        }

        return appealAction;
    };

    const renderReportCause = (cause) => {
        const reportCause = getCauseType(cause);
        return (
            <StatusIndicator
                color="info"
                badgeTitle={reportCause}
                titleClass="font-weight-bold font-size-16"
            />
        );
    };

    const renderReportStatus = (status) => {
        const reportStatus = getReportStatus(status);
        return (
            <CustomBadge color={reportStatus.color} classes={reportStatus.color}>
                {reportStatus.text}
            </CustomBadge>
        );
    };

    const renderItem = (heading, text) => (
        <p className="mb-1">
            <span className="font-weight-bold">{heading}</span> {text}
        </p>
    );

    const getReportedUserName = () => {
        return (
            reportDetails.Participants.find((user) => user.UserId === reportDetails.ReportedUserId)
                ?.Name || "-"
        );
    };

    const handleScroll = (e) => {
        e.preventDefault();
        const isTop = e.target.scrollTop < 10;

        if (isTop && totalMessageCount > messageListing.length) {
            setMessageFetching(true);
            let container = document.getElementById("chatbox-scroll-div");
            let prevScrollTop = container.scrollTop;
            let prevScrollHeight = container.scrollHeight;
            let currentPage = page + 1;
            dispatch(getMessageListing(reportDetails?.ChatId, { Page: currentPage }))
                .then((res) => {
                    setMessageListing(res.Data?.ChatMessages.reverse().concat(messageListing));
                    setTotalMessageCount(res.Data?.Count);
                    container.scrollTop =
                        container.scrollHeight - (prevScrollHeight - prevScrollTop);
                    setMessageFetching(false);
                    setPage(currentPage);
                })
                .catch((error) => {
                    setMessageFetching(false);
                });
        }
    };

    const getUserNames = () => {
        return participants
            ?.filter((item) => item.Type === Roles.USER)
            .map((item) => {
                return item.Name;
            })
            ?.join(" & ");
    };

    const getNameById = (id) => {
        return participants.find((user) => user.UserId === id)?.Name || "User";
    };

    const getUserModalTitle = () => {
        let title = "";
        switch (reportAction?.Type) {
            case ReportActions.WARNING:
                title = "which user do you want to send a warning";
                break;

            case ReportActions.REMOVE:
                title = "which user do you want to remove from platform";
                break;

            case ReportActions.SUSPEND:
                title = "which user do you wish to suspend";
                break;
            default:
        }
        return title;
    };

    const getActionMenu = () => {
        let actionMenu;

        switch (reportDetails.Status) {
            case ReportStatus.RESOLVED:
                actionMenu = null;
                break;
            case ReportStatus.PENDING_REVIEW:
            case ReportStatus.UNDER_REVIEW:
                actionMenu = reportActions;
                break;
            case ReportStatus.UNDER_APPEAL:
            case ReportStatus.APPEAL_REQUESTED:
                actionMenu = getAppealActions(reportDetails.ReportedUserStatus);
                break;
            default:
        }

        return actionMenu;
    };

    const handleUserModalClose = () => {
        setUserModal(false);
        setReportAction(null);
    };

    const shouldShowRadioBtn = () => {
        if (reportAction?.Type === ReportActions.SUSPEND) {
            return true;
        }
        return false;
    };

    const handleUserClick = (e, user) => {
        setReportAction({
            ...reportAction,
            ReportedName: user.Name,
            ReportedId: Number(user.UserId)
        });
        setUserModal(false);
        setShowReasonModal(true);
    };

    const submitWarningActionReport = (Data) => {
        setIsLoading(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setShowSuccessMsg({
                    isOpen: true,
                    message: `${getNameById(
                        Data.ReportedId
                    )}  has been warned for their violation / behaviour.`
                });
                setIsLoading(false);
                setReportDetails(res.Data);
                setShowReasonModal(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const submitSuspendActionReport = (Data) => {
        setIsLoading(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setShowSuccessMsg({
                    isOpen: true,
                    message: `${getNameById(Data.ReportedId)}  has been suspended for ${
                        Data.SuspendedDays
                    }`
                });
                setIsLoading(false);
                setReportDetails(res.Data);
                setShowReasonModal(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const onReportResolvedConfirmClick = () => {
        let details = { ...reportAction, ReportedId: reportDetails.ReportedUserId };
        setIsLoading(true);
        dispatch(sendReportAction(details))
            .then((res) => {
                setShowSuccessMsg({
                    isOpen: true,
                    message: `${getNameById(
                        reportDetails.ReportedUserId
                    )}  will be notified of your action and the case resolution.`
                });
                setIsLoading(false);
                setReportDetails(res.Data);
                setShowReportResolvedConfirmation(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const submitRemoveActionReport = (Data) => {
        setIsLoading(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setShowSuccessMsg({
                    isOpen: true,
                    message: `${getNameById(Data.ReportedId)}  has been removed.`
                });
                setIsLoading(false);
                setReportDetails(res.Data);
                setShowRemovalModal(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleBtnClick = (data) => {
        if (reportAction?.Type === ReportActions.WARNING) {
            if (!data?.remarksOption) {
                toaster({ title: "Fields are required", type: "danger" });
                return;
            }
            let detail = {
                ...reportAction,
                Remarks: data.comments,
                CauseId: Number(data.remarksOption)
            };
            setReportAction(detail);
            if (isRemoveSelected) {
                submitWarningActionReport(detail);
                return;
            }
            submitWarningActionReport(detail);
        } else if (reportAction?.Type === ReportActions.SUSPEND) {
            if (!data?.remarksOption || !data?.dayOption) {
                toaster({ title: "Fields are required", type: "danger" });
                return;
            }
            let details = {
                ...reportAction,
                Remarks: data.comments,
                CauseId: parseInt(data.remarksOption),
                SuspendedDays: data.dayOption
            };
            setReportAction(details);
            if (isRemoveSelected) {
                submitSuspendActionReport(details);
                return;
            }
            submitSuspendActionReport(details);
        } else if (reportAction?.Type === ReportActions.REMOVE) {
            if (!data?.remarksOption) {
                toaster({ title: "Fields are required", type: "danger" });
                return;
            }
            setReportAction({
                ...reportAction,
                Remarks: data.comments,
                CauseId: parseInt(data.remarksOption)
            });
            setIsRemoveSelected(true);
            setShowReasonModal(false);
            setShowRemovalModal(true);
        }
    };

    const handleRemoveFromModalClick = (data) => {
        let details = {
            ...reportAction,
            Type: data?.option || reportAction.Type
        };
        setReportAction(details);
        if (details.Type === ReportActions.WARNING) {
            setShowRemovalModal(false);
            setShowReasonModal(true);
        } else if (details.Type === ReportActions.SUSPEND) {
            setShowRemovalModal(false);
            setShowReasonModal(true);
        } else if (details.Type === ReportActions.REMOVE) {
            submitRemoveActionReport(details);
        }
    };

    return !fetching && Object.keys(reportDetails).length ? (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Report Details"} />
                <Row>
                    <Col md="6">
                        <Row>
                            <div className="d-flex align-items-center ml-3">
                                {renderReportCause(reportDetails?.Cause)}:
                                <div className="font-size-16 mb-0 ml-4 mr-4">
                                    {renderReportStatus(reportDetails?.Status)}
                                </div>
                            </div>
                            {reportDetails.Status === ReportStatus.RESOLVED ||
                            reportDetails.Status === ReportStatus.APPEAL_RESOLVED ? null : (
                                <ButtonDropdown
                                    isOpen={singleButton}
                                    direction="right"
                                    toggle={() =>
                                        setSingleButton((state) => {
                                            return !state;
                                        })
                                    }
                                >
                                    <DropdownToggle
                                        caret
                                        color="primary"
                                        className="btn btn-primary"
                                    >
                                        Actions <i className="mdi mdi-chevron-right"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {getActionMenu()?.map((menu, index) => {
                                            return (
                                                <DropdownItem key={index} onClick={menu.clickFunc}>
                                                    {menu.name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            )}
                        </Row>
                        <Card className="mt-3">
                            <CardBody>
                                <div>
                                    {renderItem("User Reported:", getReportedUserName())}
                                    {renderItem("Reported by:", reportDetails?.ReportedBy?.Name)}
                                    {renderItem(
                                        "Date:",
                                        moment(reportDetails.Date).format("DD-MM-YYYY")
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="mt-3">
                            <CardBody>
                                <div>
                                    <p className="font-weight-bold">About the incident</p>
                                    <p>{reportDetails.About || "Not available"}</p>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="mt-3">
                            <CardBody>
                                <div>
                                    <p className="font-weight-bold">Matchmaker's remarks</p>
                                    <p>{reportDetails.MatchMakerRemarks || "Not available"}</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        {reportDetails?.Appeal && (
                            <>
                                <p className="font-weight-bold font-size-16">Appeal Report</p>
                                <Card className="mt-3">
                                    <CardBody>
                                        <div>
                                            {renderItem(
                                                "Requested By:",
                                                reportDetails?.Appeal?.AppealBy
                                            )}
                                            {renderItem(
                                                "Appeal Date:",
                                                moment(reportDetails?.Appeal?.Date).format(
                                                    "DD-MM-YYYY"
                                                )
                                            )}
                                            {renderItem(
                                                "Appealing Reason:",
                                                getAppealCause(reportDetails?.Appeal?.Reason)
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </>
                        )}
                        <Card className="mt-3">
                            <p className="font-weight-bold p-2 mb-0 chat-header-bg">
                                {getUserNames()}
                            </p>

                            <CardBody
                                id="chatbox-scroll-div"
                                className="chat-card-container"
                                onScroll={handleScroll}
                            >
                                {!!messageListing.length && messageFetching && <Loader size="sm" />}
                                {messageListing.length ? (
                                    <RecordedChat
                                        messages={messageListing}
                                        participants={participants}
                                    />
                                ) : messageFetching && !messageListing.length ? (
                                    <Loader classes="h-100" />
                                ) : (
                                    <NoDataPlaceholder classes="h-100" />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {userModal && (
                <PopUpModal
                    isOpen={userModal}
                    headerTitle={getUserModalTitle()}
                    handleClose={handleUserModalClose}
                    body={
                        <div className="listing-container">
                            {
                                <ModalContent
                                    contentType={1}
                                    listing={participants}
                                    onClick={handleUserClick}
                                />
                            }
                        </div>
                    }
                />
            )}
            {showReasonModal && (
                <PopUpModal
                    isOpen={showReasonModal}
                    headerTitle="Please select between remarks below:"
                    handleClose={() => {
                        setReportAction(null);
                        setShowReasonModal(false);
                    }}
                    body={
                        <ModalContent
                            contentType={2}
                            showDaysRadioButton={shouldShowRadioBtn()}
                            onClick={handleBtnClick}
                            isLoading={isLoading}
                        />
                    }
                />
            )}
            {showRemovalModal && (
                <PopUpModal
                    isOpen={showRemovalModal}
                    handleClose={() => {
                        setReportAction(null);
                        setShowRemovalModal(false);
                    }}
                    headerTitle={`Are you sure you want to remove ${reportAction?.ReportedName} from the platform?`}
                    body={
                        <ModalContent
                            contentType={3}
                            onClick={handleRemoveFromModalClick}
                            isLoading={isLoading}
                        />
                    }
                />
            )}
            {showReportResolvedConfirmation && (
                <SweetAlert
                    title={isLoading ? "Processing" : "Resolve Confirmation"}
                    description="Are you sure you want to resolve the report?"
                    type={isLoading ? null : "success"}
                    showCancel={!isLoading}
                    showConfirm={!isLoading}
                    onConfirm={onReportResolvedConfirmClick}
                    onCancel={() => setShowReportResolvedConfirmation(false)}
                    closeOnClickOutside={false}
                    isLoading={isLoading}
                />
            )}
            {showSuccessMsg.isOpen && (
                <SweetAlert
                    title={"Success"}
                    description={showSuccessMsg.message}
                    type="success"
                    onConfirm={() => setShowSuccessMsg({ isOpen: false, message: "" })}
                    showCancel={false}
                    closeOnClickOutside={false}
                />
            )}
        </div>
    ) : !fetching && !Object.keys(reportDetails).length ? (
        <NoDataPlaceholder />
    ) : (
        <Loader classes="vh-100" />
    );
};

export default ReportDetails;
