import React from "react";
import Slider from "rc-slider";

import "./MultiSlider.scss";
import "rc-slider/assets/index.css";

const Range = Slider.createSliderWithTooltip(Slider.Range);

const handlerStyles = {
    backgroundColor: "#6246EA",
    border: 0,
    width: 19,
    height: 19,
    lineHeight: 19,
    marginTop: -8
};

const MultiSlider = (props) => {
    const { min = 0, max = 100, ...rest } = props;
    return (
        <Range
            className="multi-slider"
            min={min}
            max={max}
            marks={{ [min]: min, [max]: max }}
            handleStyle={[handlerStyles, handlerStyles]}
            trackStyle={[{ backgroundColor: "#6246EA" }]}
            tipProps={{
                placement: "bottom"
            }}
            {...rest}
        />
    );
};

export default MultiSlider;
