import React from "react";
import { Table } from "reactstrap";
import { withNamespaces } from "react-i18next";

import TableHead from "./TableHead";
import { Loader } from "../../components/Loader";

const DataTable = (props) => {
    const {
        config,
        data,
        onRowClick,
        currentSortOrder,
        currentSortColumn,
        onSortClick,
        loading = false,
        t
    } = props;

    const isRowClickable = () => {
        return onRowClick && typeof onRowClick === "function";
    };

    const handleRowClick = (e) => {
        if (isRowClickable()) {
            onRowClick(e);
        }
    };

    return (
        <div className="table-responsive table-min-height">
            <Table className="table-centered table-nowrap">
                <thead className="thead-light">
                    <tr>
                        {config.map((item, index) => {
                            return (
                                <TableHead
                                    key={index}
                                    options={item}
                                    currentSortOrder={currentSortOrder}
                                    currentSortColumn={currentSortColumn}
                                    onSortClick={onSortClick}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.length > 0 &&
                        !loading &&
                        data.map((dataObj, index) => {
                            return (
                                <tr
                                    key={index}
                                    onClick={handleRowClick}
                                    className={`${isRowClickable() ? "cursor-pointer" : ""}`}
                                >
                                    {config.map((item, index) => {
                                        return (
                                            <td
                                                key={index}
                                                className={
                                                    item.withWrap ? "multiline-truncate" : ""
                                                }
                                            >
                                                {item.render(dataObj, index)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            {(!data || !data.length) && (
                <div className="table-loader-container">
                    {loading ? (
                        <Loader />
                    ) : (
                        <p className="text-muted text-center">{t("No Data Available")}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default withNamespaces()(DataTable);
