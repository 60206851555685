import { UPDATE_DEVICE_ID } from "../actions";

const initialState = {
    deviceUUID: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DEVICE_ID:
            return { ...state, deviceUUID: action.deviceId };
        default:
            return state;
    }
};
