import { injectBaseConstantMethods } from "./BaseConstants";

const PlanCategory = {
    MONTHLY: 1,
    QUARTERLY: 2,
    YEARLY: 3,
    ALL: 4
};

const displayTextKeys = {
    [PlanCategory.MONTHLY]: "Monthly",
    [PlanCategory.QUARTERLY]: "Quarterly",
    [PlanCategory.YEARLY]: "Yearly",
    [PlanCategory.ALL]: "All"
};

const labelClass = {
    [PlanCategory.MONTHLY]: "success",
    [PlanCategory.QUARTERLY]: "info",
    [PlanCategory.YEARLY]: "warning",
    [PlanCategory.ALL]: "danger"
};

export default injectBaseConstantMethods(PlanCategory, displayTextKeys, labelClass);
