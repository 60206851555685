import Config from "../../Config";
import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import { scrollToBottom } from "../../utils/domUtils";
import { MessageStatus } from "../../constants";
// import { toaster } from "../../utils/toasterUtil";

export const CUSTOMER_QUERY_REQUEST = "CUSTOMER_QUERY_REQUEST";
export const CUSTOMER_QUERY_SUCCESS = "CUSTOMER_QUERY_SUCCESS";
export const CUSTOMER_QUERY_FAILED = "CUSTOMER_QUERY_FAILED";

export const QUERY_MESSAGE_REQUEST = "QUERY_MESSAGE_REQUEST";
export const QUERY_MESSAGE_SUCCESS = "QUERY_MESSAGE_SUCCESS";
export const QUERY_MESSAGE_FAILED = "QUERY_MESSAGE_FAILED";
export const UPDATE_CUSTOMER_QUERY = "UPDATE_CUSTOMER_QUERY";

export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";

export const getCustomerQueryListing = (params = {}, abortSignal = null) => (
    dispatch,
    getState
) => {
    dispatch({ type: CUSTOMER_QUERY_REQUEST });
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/contacts", { Limit: Config.LIMIT, ...params }),
        token,
        ...(abortSignal && { abortSignal })
    })
        .then((res) => {
            dispatch({
                type: CUSTOMER_QUERY_SUCCESS,
                payload: res?.Data?.Contacts,
                totalQueries: res.Data.TotalContacts
            });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: CUSTOMER_QUERY_FAILED });
            return Promise.reject(err);
        });
};

export const getUserContactChat = (id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    dispatch({ type: QUERY_MESSAGE_REQUEST });

    return fetchUtil({
        url: `/user/contact/${id}`,
        token
    })
        .then((res) => {
            const { Messages, ...rest } = res?.Data;
            dispatch({
                type: QUERY_MESSAGE_SUCCESS,
                details: rest,
                payload: Messages || []
            });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: QUERY_MESSAGE_FAILED });
            return Promise.reject(err);
        });
};

export const addMessage = (data) => (dispatch, getState) => {
    dispatch({
        type: ADD_NEW_MESSAGE,
        payload: data
    });
    setTimeout(() => {
        scrollToBottom();
    }, 0);
};

export const replyOnContactUs = (bodyData) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    return fetchUtil({
        url: "/user/reply-contact-us",
        token,
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            dispatch({ type: UPDATE_CUSTOMER_QUERY, payload: { Status: MessageStatus.RESOLVED } });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
