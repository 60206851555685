import {
    PLAN_LISTING_REQUEST,
    PLAN_LISTING_SUCCESS,
    PLAN_LISTING_FAILED,
    UPDATE_PAYMENT_PLAN,
    REMOVE_PAYMENT_PLAN
} from "../actions";

const initialState = {
    fetching: false,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PLAN_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: []
            };
        case PLAN_LISTING_SUCCESS:
            return {
                ...state,
                fetching: false,
                list: action.payload
            };
        case PLAN_LISTING_FAILED:
            return {
                ...state,
                fetching: false
            };
        case UPDATE_PAYMENT_PLAN:
            return {
                ...state,
                list: state.list.map((item) => {
                    if (item.Id === action.payload.Id) {
                        return {
                            ...item,
                            ...action.payload
                        };
                    }
                    return item;
                })
            };
        case REMOVE_PAYMENT_PLAN:
            return {
                ...state,
                list: state.list.filter((item) => item.Id !== action.id)
            };
        default:
            return state;
    }
};
