import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Collapse } from "reactstrap";

import { FilterButton } from "../../components/FilterButton";
import { SearchableSelect } from "../../components/InputSelect";

const SelectFilter = (props) => {
    const { isOpen, title, requestAction, getOptions, placeholder, filterValue, onChange } = props;
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setFetching(true);
        dispatch(requestAction())
            .then((res) => {
                setData(res?.Data);
            })
            .catch((err) => {})
            .finally(() => {
                setFetching(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLabel = () => {
        if (filterValue && filterValue.label) {
            let val = `${filterValue.label}`;
            return val;
        }
        return "";
    };

    return (
        <div className="mb-3">
            {title && (
                <div className="d-flex mb-4">
                    <FilterButton text={`${title}: ${getLabel()}`} />
                </div>
            )}
            <Collapse isOpen={isOpen}>
                <SearchableSelect
                    options={getOptions(data)}
                    isLoading={fetching}
                    placeholder={placeholder}
                    closeMenuOnSelect={false}
                    isClearable
                    value={filterValue}
                    onChange={onChange}
                />
            </Collapse>
        </div>
    );
};

export default SelectFilter;
