import React from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import { withNamespaces } from "react-i18next";

const SearchHeader = (props) => {
    const {
        t,
        value,
        onChange,
        buttonText,
        onButtonClick,
        showButton = true,
        customButton = false,
        showSearchField = true,
        headerLeftUI = false
    } = props;
    return (
        <Row className="mb-2">
            <Col sm="4">
                {showSearchField && (
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <DebounceInput
                                element={Input}
                                debounceTimeout={500}
                                placeholder={t("Search")}
                                value={value}
                                onChange={onChange}
                                className="form-control"
                            />

                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                )}
                {headerLeftUI ? headerLeftUI : null}
            </Col>
            {showButton && (
                <Col sm="8">
                    <div className="text-sm-right">
                        {!customButton ? (
                            <Button
                                onClick={onButtonClick}
                                type="button"
                                color="primary"
                                className="btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                                <i className="mdi mdi-plus mr-1"></i> {buttonText}
                            </Button>
                        ) : (
                            customButton
                        )}
                    </div>
                </Col>
            )}
        </Row>
    );
};

export default withNamespaces()(SearchHeader);
