import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./assets/scss/theme.scss";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import { DefaultLayout } from "./containers";
import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Register";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import { withTitle } from "./hoc";
import { PageTitles } from "./constants";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const LoginWithTitle = withTitle({
            component: Login,
            title: PageTitles.LOGIN
        });
        const ForgotPasswordWithTitle = withTitle({
            component: ForgetPassword,
            title: PageTitles.FORGOT_PASSWORD
        });
        const ResetPasswordWithTitle = withTitle({
            component: ResetPassword,
            title: PageTitles.RESET_PASSWORD
        });
        const RegisterWithTitle = withTitle({
            component: Register,
            title: PageTitles.SIGN_UP
        });
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route path="/login" name="Login" component={LoginWithTitle} />
                        <Route
                            path="/forgot-password"
                            name="ForgotPassword"
                            component={ForgotPasswordWithTitle}
                        />
                        <Route path="/register" name="Register" component={RegisterWithTitle} />
                        <Route
                            path="/reset-password"
                            name="Register"
                            component={ResetPasswordWithTitle}
                        />
                        <Route
                            path="/dashboard"
                            name="Dashboard"
                            render={(props) => (
                                <DefaultLayout {...props} Layout={this.getLayout()} />
                            )}
                        />
                        <Redirect to="/login" />
                    </Switch>
                </Router>
                <ToastContainer
                    toastClassName="toast-container"
                    bodyClassName="w-100"
                    position="bottom-right"
                    autoClose={2500}
                    hideProgressBar
                    closeOnClick
                    draggable
                    pauseOnHover
                    closeButton={false}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);
