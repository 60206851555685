import { APP_ROUTES } from "./helpers/routeHelpers";

const sidebarConfig = [
    {
        type: "title",
        title: "Menu"
    },
    {
        title: "Dashboard",
        url: APP_ROUTES.DASHBOARD,
        icon: "bx bx-home-circle"
    },

    {
        title: "Members",
        url: "/dashboard",
        icon: "bx bx-user",
        children: [
            {
                title: "Users",
                url: "/dashboard/user-listing"
            },
            {
                title: "Guardians",
                url: "/dashboard/guardian-listing"
            },
            {
                title: "Match Makers",
                url: "/dashboard/matchmaker-listing"
            }
        ]
    },

    {
        title: "Payment Plans",
        url: APP_ROUTES.PAYMENT_LISTING,
        icon: "bx bx-money"
    },
    {
        title: "Customer Inquiries",
        url: APP_ROUTES.CUSTOMER_QUERIES,
        icon: "bx bxs-user-voice"
    },
    {
        title: "Reports",
        url: APP_ROUTES.REPORT_LISTING,
        icon: "bx bxs-report"
    }
];

export default sidebarConfig;
