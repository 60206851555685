import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.history.push("/reset-password");
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">
                                                        {this.props.t("Welcome Back")}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal mt-4"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                <div className="form-group">
                                                    <AvField
                                                        name="email"
                                                        label={t("Email")}
                                                        className="form-control"
                                                        placeholder={t("Enter Email")}
                                                        type="email"
                                                        required
                                                    />
                                                </div>
                                                <Row className="form-group">
                                                    <Col className="text-right">
                                                        <button
                                                            className="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            {t("Reset")}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        {t("Go back to ")}
                                        <Link
                                            to="login"
                                            className="font-weight-medium text-primary"
                                        >
                                            {t("Login")}
                                        </Link>{" "}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return {};
};

export default withRouter(connect(mapStatetoProps, null)(withNamespaces()(ForgetPassword)));
