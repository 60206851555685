import moment from "moment";

export const dateTimeFormat = {
    appDateTimeFormat: "DD-MM-YYYY, HH:mm",
    appDateFormat: "DD-MM-YYYY",
    apiDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    apiDateFormat: "YYYY-MM-DD",
    longDateFormat: "dd-MMM-yyyy",
    gmtDateTimeFormat: "YYYY-MM-DD HH:mm",
    commissionDateQuery: "YYYY-MM",
    salesReportYearFormat: "YYYY"
};

export const convertDateTime = ({ to, date, dateOnly = false, customFormat = null }) => {
    const format = customFormat
        ? customFormat
        : dateOnly
        ? dateTimeFormat.apiDateFormat
        : dateTimeFormat.apiDateTimeFormat;

    return moment(date).format(format);
};

export const convertTime = ({ tz, time, format }) => {
    if (tz === "GMT_TIME") {
        return moment(time, format).utc().format(format);
    } else if (tz === "LOCAL_TIME") {
        return moment.utc(time, format).local().format(format);
    }
};

export const convertDateTimeGMTFormat = (date) => {
    const startOf = () => {
        return moment(date).startOf("day").format(dateTimeFormat.gmtDateTimeFormat);
    };
    const endOf = () => {
        return moment(date).endOf("day").format(dateTimeFormat.gmtDateTimeFormat);
    };
    return {
        startOf,
        endOf
    };
};

export const convertDateToInvoicePattern = (date) => {
    const getPreviousMonthDate = () => {
        return moment(date).subtract(1, "month").format("LL");
    };
    const getPreviousDayDate = () => {
        return moment(date).subtract(1, "day").format("LL");
    };
    return {
        getPreviousMonthDate,
        getPreviousDayDate
    };
};

export const getTimeWithSeconds = (time, format, requiredFormat) => {
    return moment(time, format).format(requiredFormat);
};
