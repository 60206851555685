import React from "react";

const Avatar = (props) => {
    const { url, title } = props;
    return url ? (
        <img src={url} className="rounded-circle avatar-xs" alt="" />
    ) : (
        <div className="avatar-xs d-inline-block">
            <span className="avatar-title rounded-circle">{title || "0"}</span>
        </div>
    );
};

export default Avatar;
