import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";

import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { SweetAlert } from "../../components/Alert";
import { CustomBadge } from "../../components/Badge";
import { SearchHeader } from "../../components/SearchHeader";
import { getMatchMakerListing, removeUser, matchMakerRestore } from "../../store/actions";
import { UserRoleTypes, UserStatus } from "../../constants";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import { toaster } from "../../utils/toasterUtil";
import { InvitationModal } from "../../components/InvitationModal";

const MATCH_MAKER_COLUMNS = {
    FULL_NAME: "Full Name",
    EMAIL: "Email",
    STATUS: "Status",
    JOINING_DATE: "Joining Date",
    ACTIONS: "Actions"
};

const ALERT_MODES = {
    DELETE: "DELETE",
    RESTORE: "RESTORE"
};

const MatchMakers = (props) => {
    const {
        getMatchMakerListing,
        data,
        totalMatchMakers,
        fetching,
        removeUser,

        matchMakerRestore
    } = props;

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [matchMakerModal, setMatchMakerModal] = useState(false);

    const matchMakerListingAbortController = useRef(null);

    useEffect(() => {
        matchMakerListingAbortController.current = new AbortController();

        getMatchMakerListing(
            {
                Page: page,
                ...(searchText && { Q: searchText })
            },
            matchMakerListingAbortController.current.signal
        );

        return () => {
            if (matchMakerListingAbortController.current.signal) {
                matchMakerListingAbortController.current.abort();
            }
        };

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchText, getMatchMakerListing]);

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const handleSearchChange = (e) => {
        setPage(1);
        setSearchText(e.target.value);
    };

    const toggleUserDeleteAlert = (id) => {
        setSelectedUserId(id);
        setShowAlert(ALERT_MODES.DELETE);
    };

    const toggleUserRestoreAlert = (id) => {
        setSelectedUserId(id);
        setShowAlert(ALERT_MODES.RESTORE);
    };

    const handleUserDelete = () => {
        setIsLoading(true);

        let payload = {
            UserId: selectedUserId
        };

        removeUser(payload)
            .then((res) => {
                toaster({ title: props.t(res.Message), type: "success" });
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
                setShowAlert(false);
                setSelectedUserId("");
            });
    };

    const handleUserRestore = () => {
        let payload = {
            UserId: selectedUserId
        };

        setIsLoading(true);
        matchMakerRestore(payload)
            .then((res) => {
                toaster({ title: props.t(res.Message), type: "success" });
            })

            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
                setShowAlert(false);
            });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Dashboard")}
                        breadcrumbItem={props.t("Match Makers")}
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <SearchHeader
                                        buttonText="Invite a Match Maker"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                        onButtonClick={() => setMatchMakerModal(true)}
                                    />

                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        config={[
                                            {
                                                title: MATCH_MAKER_COLUMNS.FULL_NAME,
                                                render: (data) => data.FullName
                                            },
                                            {
                                                title: MATCH_MAKER_COLUMNS.EMAIL,
                                                render: (data) => data.Email
                                            },
                                            {
                                                title: MATCH_MAKER_COLUMNS.STATUS,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={UserStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                        classes={UserStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                    >
                                                        {props.t(
                                                            UserStatus.getDisplayTextKey(
                                                                data.Status
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: MATCH_MAKER_COLUMNS.JOINING_DATE,
                                                render: (data) =>
                                                    convertDateTime({
                                                        date: data?.JoiningDate,
                                                        customFormat: dateTimeFormat.appDateFormat
                                                    })
                                            },
                                            {
                                                title: MATCH_MAKER_COLUMNS.ACTIONS,
                                                render: (data, index) => {
                                                    return (
                                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                                            {data.Status === UserStatus.SUSPENDED ||
                                                            data.Status === UserStatus.DELETED ||
                                                            data.Status === UserStatus.REMOVED ||
                                                            data.Status ===
                                                                UserStatus.MANUAL_DELETED ? (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"restore" + data.UserId}
                                                                        className="text-success cursor-pointer"
                                                                        onClick={() =>
                                                                            toggleUserRestoreAlert(
                                                                                data.UserId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-lock-open-check"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "restore" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("Restore")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            ) : (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"delete" + data.UserId}
                                                                        className="text-danger cursor-pointer"
                                                                        onClick={() =>
                                                                            toggleUserDeleteAlert(
                                                                                data.UserId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-trash-can"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "delete" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("Delete")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    );
                                                }
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={totalMatchMakers}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <InvitationModal
                    isOpen={matchMakerModal}
                    closeModal={() => setMatchMakerModal(false)}
                    roleType={UserRoleTypes.MATCH_MAKER}
                />
                {showAlert ? (
                    <SweetAlert
                        title={
                            isLoading
                                ? props.t("Processing")
                                : showAlert === ALERT_MODES.DELETE
                                ? props.t("Delete")
                                : props.t("Restore")
                        }
                        description={
                            showAlert === ALERT_MODES.DELETE
                                ? props.t("Are you sure want to delete this Match Maker ?")
                                : props.t("Are you sure want to restore this Match Maker ?")
                        }
                        type={isLoading ? null : "warning"}
                        showCancel={!isLoading}
                        showConfirm={!isLoading}
                        onConfirm={() =>
                            showAlert === ALERT_MODES.DELETE
                                ? handleUserDelete()
                                : handleUserRestore()
                        }
                        onCancel={() => setShowAlert(false)}
                        success={isLoading ? null : true}
                        closeOnClickOutside={!isLoading}
                        isLoading={isLoading}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = ({ users }) => {
    return {
        data: users.list,
        fetching: users.fetching,
        totalMatchMakers: users.totalUsers
    };
};

const mapDispatchToProps = {
    getMatchMakerListing,
    removeUser,
    matchMakerRestore
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchMakers);
