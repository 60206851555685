import React from "react";

import { UncontrolledAlert } from "reactstrap";

const Toaster = ({ type = "success", title = "", onClick }) => {
    const handleToasterClick = (e) => {
        e.preventDefault();
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <UncontrolledAlert className="m-0" onClick={handleToasterClick} role="alert" color={type}>
            {title}
        </UncontrolledAlert>
    );
};

export default Toaster;
