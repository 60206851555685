import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Loader } from "../Loader";

const Alert = ({
    title = "",
    description = "",
    onConfirm = undefined,
    onCancel = undefined,
    showCancel = true,
    showConfirm = true,
    type = "success",
    success = true,
    closeOnClickOutside = true,
    isLoading = false
}) => {
    return (
        <SweetAlert
            title={title}
            type={type}
            success={success}
            showCancel={showCancel}
            showConfirm={showConfirm}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={onConfirm}
            onCancel={onCancel}
            closeOnClickOutside={closeOnClickOutside}
        >
            {isLoading ? <Loader classes={"mt-4 mb-4"} /> : description}
        </SweetAlert>
    );
};

export default Alert;
