import { injectBaseConstantMethods } from "./BaseConstants";

const USER_INQUIRY_TYPES = {
    INQUIRY: 1,
    APPLICATION_PROBLEM: 2
};

const displayTextKeys = {
    [USER_INQUIRY_TYPES.INQUIRY]: "Contact us",
    [USER_INQUIRY_TYPES.APPLICATION_PROBLEM]: "Report a problem"
};

const labelClass = {
    [USER_INQUIRY_TYPES.INQUIRY]: "success",
    [USER_INQUIRY_TYPES.APPLICATION_PROBLEM]: "info"
};

export default injectBaseConstantMethods(USER_INQUIRY_TYPES, displayTextKeys, labelClass);
