import { CauseType, ReportStatus, AppealCause } from "../constants";

export const getCauseType = (code) => {
    let status = "";
    switch (code) {
        case CauseType.BREAKING_PRIVACY_POLICY:
            status = "Breaking Privacy Policy";
            break;
        case CauseType.BULLYING:
            status = "Bullying";
            break;
        case CauseType.DISCRIMINATION:
            status = "Discrimination (Racism/ Xenophobia )";
            break;
        case CauseType.HARASSMENT:
            status = "Harassment";
            break;
        case CauseType.IMPERSONATING_TO_BE_SOMEONE:
            status = "Impersonating To Be Someone";
            break;
        case CauseType.NO_POLYGAMY:
            status = "No Polygamy";
            break;
        case CauseType.SEXUAL_CONTENT:
            status = "Sexual Content";
            break;
        case CauseType.INAPPROPRIATE_LANGUAGE:
            status = "Inappropriate Language";
            break;
        case CauseType.VIOLENCE:
            status = "Violence";
            break;
        default:
    }
    return status;
};

export const getReportStatus = (code) => {
    let status = {};
    switch (code) {
        case ReportStatus.PENDING_REVIEW:
            status.color = "warning";
            status.text = "Pending Review";
            break;
        case ReportStatus.UNDER_REVIEW:
            status.color = "info";
            status.text = "Under Review";
            break;
        case ReportStatus.RESOLVED:
            status.color = "success";
            status.text = "Resolved";
            break;
        case ReportStatus.APPEAL_REQUESTED:
            status.color = "warning";
            status.text = "Appeal Requested";
            break;
        case ReportStatus.UNDER_APPEAL:
            status.color = "info";
            status.text = "Under Appeal";
            break;
        case ReportStatus.APPEAL_RESOLVED:
            status.color = "success";
            status.text = "Appeal Resolved";
            break;
        default:
    }
    return status;
};

export const getAppealCause = (code) => {
    let status = "";
    switch (code) {
        case AppealCause.FALSE_CLAIM:
            status = "False Claim";
            break;
        case AppealCause.WRONG_FULL_REMOVAL:
            status = "Wrongful Removal";
            break;
        case AppealCause.WRONG_FULL_SUSPENSION:
            status = "Wrongful Suspension";
            break;
        default:
    }
    return status;
};
