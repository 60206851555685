export const UserHalalPreference = {
    STRICT: 1,
    MODERATE: 2,
    LIBERAL: 3
};

export const UserHalalPreferenceLabels = {
    [UserHalalPreference.STRICT]: "Strict",
    [UserHalalPreference.MODERATE]: "Moderate",
    [UserHalalPreference.LIBERAL]: "Liberal"
};
