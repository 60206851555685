import { injectBaseConstantMethods } from "./BaseConstants";

const UserMessageType = {
    INQUIRY: 1,
    APPLICATION_PROBLEM: 2
};

const displayTextKeys = {
    [UserMessageType.INQUIRY]: "Inquiry",
    [UserMessageType.APPLICATION_PROBLEM]: "Application Problem"
};

const labelClass = {
    [UserMessageType.INQUIRY]: "success",
    [UserMessageType.APPLICATION_PROBLEM]: "info"
};

export default injectBaseConstantMethods(UserMessageType, displayTextKeys, labelClass);
