import React, { useState, useEffect } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { Col, Card, CardBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";

import "./DashboardFilter.scss";
import OptionFilter from "./OptionFilter";
import RangeFilter from "./RangeFilter";
import SelectFilter from "./SelectFilter";
import DashboardUserFilter from "./DashboardUserFilter/DashboardUserFilter";
import DashboardReportFilter from "./DashboardReportFilter/DashboardReportFilter";
import DashboardMatchFilter from "./DashboardMatchFilter/DashboardMatchFilter";
import DashboardRevenueFilter from "./DashboardRevenueFilter/DashboardRevenueFilter";
import DashboardInquiryFilter from "./DashboardInquiryFilter/DashboardInquiryFilter";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import {
    DashboardUserFilterTypes,
    GenderType,
    PlanCategory,
    DashboardAnalyticsTypes
} from "../../constants";
import { getCountryListing, getAnalyticsData } from "../../store/actions";

const DashboardFilter = (props) => {
    const { usersData, customerData } = props;
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filters, setFilters] = useState({
        gender: null,
        plan: null,
        user: DashboardUserFilterTypes.ALL_USERS,
        minAge: null,
        maxAge: null,
        location: []
    });
    const dispatch = useDispatch();
    const { type } = useParams();

    useEffect(() => {
        if (!usersData?.TotalUsers) {
            dispatch(getAnalyticsData(DashboardAnalyticsTypes.USERS));
        }
        if (!customerData?.FreeUsers) {
            dispatch(getAnalyticsData(DashboardAnalyticsTypes.CUSTOMERS));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleFilterChange = (filter, value) => {
        setFilters((prev) => ({
            ...prev,
            [filter]: value
        }));
    };

    const prepareUserFilterValues = () => {
        return {
            [DashboardUserFilterTypes.ALL_USERS]: usersData?.TotalUsers,
            [DashboardUserFilterTypes.FREE_USERS]: customerData?.FreeUsers,
            [DashboardUserFilterTypes.PAID_USERS]: customerData?.PaidUsers,
            [DashboardUserFilterTypes.GUARDIAN]: usersData?.Guardians,
            [DashboardUserFilterTypes.TOTAL_ACTIVE_USERS]: usersData?.TotalActiveUsers
        };
    };

    const renderCommonFilters = () => {
        return (
            <React.Fragment>
                {type !== DashboardAnalyticsTypes.REVENUE && (
                    <Col md="2">
                        <OptionFilter
                            isOpen={isFilterOpen}
                            filterConstants={DashboardUserFilterTypes}
                            selectedFilterValue={filters.user}
                            onChange={(val) => handleFilterChange("user", val)}
                            filterValues={prepareUserFilterValues()}
                            required={true}
                            customButton={(label, value) => (
                                <Card className="mb-3">
                                    <CardBody className="p-2">
                                        <div className="d-flex">
                                            <div className="w-100 font-weight-bold">{label}</div>
                                            <div className="font-weight-bold">{value}</div>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        />
                    </Col>
                )}
                {(isFilterOpen || filters.gender) && (
                    <Col md="2">
                        <OptionFilter
                            title="Gender"
                            isOpen={isFilterOpen}
                            filterConstants={GenderType}
                            selectedFilterValue={filters.gender}
                            onChange={(val) => handleFilterChange("gender", val)}
                        />
                    </Col>
                )}
                {(isFilterOpen || (filters.minAge && filters.maxAge)) && (
                    <Col md="2">
                        <RangeFilter
                            min={18}
                            max={60}
                            minValue={filters.minAge}
                            maxValue={filters.maxAge}
                            title="Age"
                            isOpen={isFilterOpen}
                            onChange={(minVal, maxVal) => {
                                handleFilterChange("minAge", minVal);
                                handleFilterChange("maxAge", maxVal);
                            }}
                        />
                    </Col>
                )}
                {(isFilterOpen || !!filters.location?.length) && (
                    <Col md="2">
                        <SelectFilter
                            requestAction={getCountryListing}
                            isOpen={isFilterOpen}
                            title="Location"
                            placeholder="Select Location"
                            filterValue={filters.location}
                            onChange={(val) => handleFilterChange("location", val || [])}
                            getOptions={(data) =>
                                data.map((item) => ({
                                    label: item.Name,
                                    value: item
                                }))
                            }
                        />
                    </Col>
                )}
                {(isFilterOpen || filters.plan) && (
                    <Col md="2">
                        <OptionFilter
                            title="Payment plan"
                            isOpen={isFilterOpen}
                            filterConstants={PlanCategory}
                            selectedFilterValue={filters.plan}
                            onChange={(val) => handleFilterChange("plan", val)}
                        />
                    </Col>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className="page-content">
            <Switch>
                <Route
                    path={getRoute(APP_ROUTES.DASHBOARD_FILTERS, {
                        type: DashboardAnalyticsTypes.USERS
                    })}
                >
                    <DashboardUserFilter
                        filters={filters}
                        commonFilters={renderCommonFilters()}
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        prepareUserFilterValues={prepareUserFilterValues}
                    />
                </Route>
                <Route
                    path={getRoute(APP_ROUTES.DASHBOARD_FILTERS, {
                        type: DashboardAnalyticsTypes.REPORTS
                    })}
                >
                    <DashboardReportFilter
                        filters={filters}
                        commonFilters={renderCommonFilters()}
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        prepareUserFilterValues={prepareUserFilterValues}
                    />
                </Route>
                <Route
                    path={getRoute(APP_ROUTES.DASHBOARD_FILTERS, {
                        type: DashboardAnalyticsTypes.MATCHES
                    })}
                >
                    <DashboardMatchFilter
                        filters={filters}
                        commonFilters={renderCommonFilters()}
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        prepareUserFilterValues={prepareUserFilterValues}
                    />
                </Route>
                <Route
                    path={getRoute(APP_ROUTES.DASHBOARD_FILTERS, {
                        type: DashboardAnalyticsTypes.REVENUE
                    })}
                >
                    <DashboardRevenueFilter
                        filters={filters}
                        commonFilters={renderCommonFilters()}
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        prepareUserFilterValues={prepareUserFilterValues}
                    />
                </Route>
                <Route
                    path={getRoute(APP_ROUTES.DASHBOARD_FILTERS, {
                        type: DashboardAnalyticsTypes.INQUERIES
                    })}
                >
                    <DashboardInquiryFilter
                        filters={filters}
                        commonFilters={renderCommonFilters()}
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        prepareUserFilterValues={prepareUserFilterValues}
                    />
                </Route>
            </Switch>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        usersData: state.analytics[DashboardAnalyticsTypes.USERS],
        customerData: state.analytics[DashboardAnalyticsTypes.CUSTOMERS]
    };
};

export default connect(mapStateToProps, null)(DashboardFilter);
