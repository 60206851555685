import { store } from "../store";
import { AuthCode } from "../constants";
import { SIGN_OUT } from "../store/actions/authActions";
import { toaster } from "../utils/toasterUtil";

export const handleFetchError = async (res) => {
    const response = await res.clone().json();
    if (
        response.Status === AuthCode.INTERNAL_SERVER_ERROR ||
        response.Status === AuthCode.SERVICE_UNAVAILABLE
    ) {
        toaster({ title: response.Message, type: "danger" });
        throw await res.json();
    } else if (
        response.Status >= AuthCode.BAD_REQUEST &&
        response.Status < AuthCode.CONNECTION_TIMED_OUT
    ) {
        if (response.Status === AuthCode.UNAUTHORIZED) {
            store.dispatch({ type: SIGN_OUT });
            localStorage.clear();
        }
        toaster({ title: response.Message, type: "danger" });
        throw await res.json();
    }

    return res.json();
};
