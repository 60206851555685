import React, { useMemo, Fragment } from "react";
import { Pagination as PaginationContainer, PaginationItem, PaginationLink } from "reactstrap";

import Config from "../../Config";

const MAX_PAGE_VIEW_LIMIT = 4;

const Pagination = (props) => {
    const { currentPage, totalData, rowLimit = Config.LIMIT, onPageClick } = props;
    const totalPages = useMemo(() => {
        return Math.ceil(totalData / rowLimit);
    }, [totalData, rowLimit]);

    const handlePageClick = (val) => {
        if (onPageClick && typeof onPageClick === "function") {
            onPageClick(val);
        }
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1);
        }
    };

    const renderPages = () => {
        let pages =
            totalPages > MAX_PAGE_VIEW_LIMIT
                ? new Array(MAX_PAGE_VIEW_LIMIT).fill("")
                : new Array(totalPages).fill("");

        return pages.map((_, index) => {
            return (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(index + 1)}>
                        {index + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        });
    };

    return (
        totalPages > 1 && (
            <PaginationContainer className="pagination pagination-rounded justify-content-end mb-2">
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous tag="button" onClick={handlePreviousClick}>
                        <i className="bx bx-chevron-left"></i>
                    </PaginationLink>
                </PaginationItem>

                {renderPages()}
                {currentPage > MAX_PAGE_VIEW_LIMIT + 1 && (
                    <PaginationItem active={false}>
                        <PaginationLink tag="button">....</PaginationLink>
                    </PaginationItem>
                )}

                {totalPages > MAX_PAGE_VIEW_LIMIT && (
                    <Fragment>
                        <PaginationItem active={currentPage > MAX_PAGE_VIEW_LIMIT}>
                            <PaginationLink tag="button">
                                {currentPage > MAX_PAGE_VIEW_LIMIT ? currentPage : "..."}
                            </PaginationLink>
                        </PaginationItem>
                        {currentPage === MAX_PAGE_VIEW_LIMIT + 1 && (
                            <PaginationItem active={false}>
                                <PaginationLink tag="button">....</PaginationLink>
                            </PaginationItem>
                        )}
                        {currentPage !== totalPages && (
                            <PaginationItem>
                                <PaginationLink
                                    tag="button"
                                    onClick={() => handlePageClick(totalPages)}
                                >
                                    {totalPages}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                    </Fragment>
                )}
                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next tag="button" onClick={handleNextClick}>
                        <i className="bx bx-chevron-right"></i>
                    </PaginationLink>
                </PaginationItem>
            </PaginationContainer>
        )
    );
};

export default Pagination;
