import React from "react";

import "./LisItem.scss";

const ListItem = (props) => {
    const { label = "", value = "", labelClasses = "font-weight-bold", valueClasses = "" } = props;

    return (
        <div>
            <span className={"header-color " + labelClasses}>{label}</span> :{" "}
            <span className={"" + valueClasses}>{value}</span>
        </div>
    );
};

export default ListItem;
