import React, { useState } from "react";
import { Roles } from "../../constants";

import "./ReportDetails.scss";

import { RadioButton } from "../../components/RadioButton";
import { Button } from "../../components/Button";
import { getCauseType } from "../../utils/appUtils";
import { CauseType } from "../../constants";

const MODAL_TYPES = {
    USER_LISTING: 1,
    REASON_MODAL: 2,
    REMOVE_MODAL: 3
};

const daysOption = [
    {
        label: "7 Days",
        value: "7",
        radioName: "daysRadioButton"
    },
    {
        label: "14 Days",
        value: "14",
        radioName: "daysRadioButton"
    }
];

const remarksOption = Object.keys(CauseType).map((key) => {
    return {
        label: getCauseType(CauseType[`${key}`]),
        value: CauseType[`${key}`],
        radioName: "radioButton"
    };
});

const ModalContent = ({
    contentType,
    listing = [],
    onClick,
    showDaysRadioButton = false,
    isLoading
}) => {
    let content = null;
    const [data, setData] = useState(null);

    const onChangeComments = (evt) => {
        if (evt.target.value) {
            setData({ ...data, comments: evt.target.value });
        }
    };

    const onChangeRemarks = (evt) => {
        setData({
            ...data,
            remarksOption: evt.target.value
        });
    };

    const onChangeDays = (event) => {
        setData({
            ...data,
            dayOption: event.target.value
        });
    };

    const onChange = (e) => {
        setData({ option: Number(e.target.value) });
    };

    switch (contentType) {
        case MODAL_TYPES.USER_LISTING:
            content = listing
                .filter((user) => user.Type === Roles.USER)
                .map((user, index) => (
                    <div
                        className="listing-elements d-flex flex-row justify-content-between cursor-pointer"
                        key={index}
                        onClick={(e) => onClick(e, user)}
                    >
                        <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
                            {user?.MediaPath && (
                                <div className="image">
                                    <img src={user.MediaPath} alt="alt" />
                                </div>
                            )}
                            <h6>{user.Name || "Not Available"}</h6>
                        </div>
                        <div>
                            <i className="dripicons-chevron-right"></i>
                        </div>
                    </div>
                ));
            break;
        case MODAL_TYPES.REASON_MODAL:
            content = (
                <div>
                    <div className="d-flex flex-row justify-content-between radio-button-row flex-wrap pl-2">
                        {remarksOption.map((singleRadio, key) => {
                            return (
                                <div className="radio-items w-50 p-3" key={key}>
                                    <RadioButton
                                        radioName={singleRadio.radioName}
                                        label={singleRadio.label}
                                        value={singleRadio.value}
                                        onChange={onChangeRemarks}
                                        difference={key}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {showDaysRadioButton && (
                        <>
                            <div className="h6 color-secondary p-3">
                                What will be the duration of this suspention?
                            </div>
                            <div className="d-flex flex-row justify-content-between radio-button-row flex-wrap pl-2">
                                {daysOption.map((singleRadio, key) => {
                                    return (
                                        <div className="radio-items w-50 p-3" key={key}>
                                            <RadioButton
                                                radioName={singleRadio.radioName}
                                                label={singleRadio.label}
                                                value={singleRadio.value}
                                                difference={key}
                                                onChange={onChangeDays}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    <div className="h6 color-secondary p-3">
                        Please type the warning remarks below:
                    </div>
                    <div className="radio-button-container text-center pl-3 pb-3 pr-3">
                        <div className="d-flex flex-column justify-content-between radio-button-row flex-wrap radio-button-row-reduce-padding">
                            <div className="form-group">
                                <textarea
                                    className="form-control resize-textBox"
                                    rows="4"
                                    onChange={onChangeComments}
                                    maxLength="1000"
                                ></textarea>
                            </div>
                            <Button
                                className="btn btn-xs button-type-3 reduce-radius"
                                onClick={() => onClick(data)}
                                isLoading={isLoading}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            );
            break;
        case MODAL_TYPES.REMOVE_MODAL:
            content = (
                <div>
                    <div className="p-3 text-center">
                        You can choose from other actions as well:
                    </div>
                    <div className="p-3">
                        <RadioButton
                            radioName="removal-modal"
                            label="Warning"
                            value={1}
                            difference="1"
                            onChange={onChange}
                        />
                    </div>
                    <div className="p-3">
                        <RadioButton
                            radioName="removal-modal"
                            label="Suspension"
                            value={2}
                            difference="2"
                            onChange={onChange}
                        />
                    </div>
                    <div className="p-3">
                        <RadioButton
                            radioName="removal-modal"
                            label="Removal"
                            value={3}
                            difference="3"
                            onChange={onChange}
                        />
                    </div>
                    <div className="pl-3 pb-3 pr-3 d-flex justify-content-center">
                        <Button
                            className="btn btn-xs button-type-3"
                            isLoading={isLoading}
                            onClick={() => onClick(data)}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            );
            break;
        default:
    }
    return content;
};

export default ModalContent;
