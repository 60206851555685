import { combineReducers } from "redux";

import { SIGN_OUT } from "../actions";
import Layout from "../layout/reducer";
import authReducer from "./authReducer";
import root from "./rootReducer";
import entityReducer from "./entityReducer";
import deviceIdReducer from "./deviceIdReducer";
import paymentReducer from "./paymentReducer";
import customerQueryReducer from "./customerQueryReducer";
import reportReducer from "./reportReducer";
import userReducer from "./userReducer";
import analyticsReducer from "./analyticsReducer";

const appReducer = combineReducers({
    Layout,
    root,
    userAuth: authReducer,
    analytics: analyticsReducer,
    entities: entityReducer,
    deviceId: deviceIdReducer,
    payment: paymentReducer,
    customerQuery: customerQueryReducer,
    report: reportReducer,
    users: userReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        const { Layout, deviceId } = state;
        state = { Layout, deviceId };
    }

    return appReducer(state, action);
};

export default rootReducer;
