import { injectBaseConstantMethods } from "./BaseConstants";

const REPORT_CAUSE = {
    BREAKING_PRIVACY_POLICY: 1,
    BULLYING: 2,
    DISCRIMINATION: 3,
    HARASSMENT: 4,
    IMPERSONATING_TO_BE_SOMEONE: 5,
    INAPPROPRIATE_LANGUAGE: 6,
    NO_POLYGAMY: 7,
    SEXUAL_CONTENT: 8,
    VIOLENCE: 9
};

const displayTextKeys = {
    [REPORT_CAUSE.BREAKING_PRIVACY_POLICY]: "Breaking Privacy Policy",
    [REPORT_CAUSE.BULLYING]: "Bullying",
    [REPORT_CAUSE.DISCRIMINATION]: "Discrimination",
    [REPORT_CAUSE.HARASSMENT]: "Harassment",
    [REPORT_CAUSE.IMPERSONATING_TO_BE_SOMEONE]: "Impersonating to be someone",
    [REPORT_CAUSE.INAPPROPRIATE_LANGUAGE]: "Inappropriate Language",
    [REPORT_CAUSE.NO_POLYGAMY]: "No Polygamy",
    [REPORT_CAUSE.SEXUAL_CONTENT]: "Sexual content",
    [REPORT_CAUSE.VIOLENCE]: "Violence"
};

const labelClass = {
    [REPORT_CAUSE.BREAKING_PRIVACY_POLICY]: "info",
    [REPORT_CAUSE.BULLYING]: "info",
    [REPORT_CAUSE.DISCRIMINATION]: "info",
    [REPORT_CAUSE.HARASSMENT]: "info",
    [REPORT_CAUSE.IMPERSONATING_TO_BE_SOMEONE]: "info",
    [REPORT_CAUSE.INAPPROPRIATE_LANGUAGE]: "info",
    [REPORT_CAUSE.NO_POLYGAMY]: "info",
    [REPORT_CAUSE.SEXUAL_CONTENT]: "info",
    [REPORT_CAUSE.VIOLENCE]: "info"
};

export default injectBaseConstantMethods(REPORT_CAUSE, displayTextKeys, labelClass);
