import React from "react";
import { Collapse } from "reactstrap";

import { FilterButton } from "../../components/FilterButton";

const OptionFilter = (props) => {
    const {
        title,
        isOpen,
        filterConstants = {},
        selectedFilterValue,
        onChange,
        filterValues = null,
        customButton = null,
        required = false
    } = props;

    const handleFilterClick = (value) => {
        if (onChange && typeof onChange === "function") {
            if (value === selectedFilterValue && !required) {
                onChange(null);
            } else {
                onChange(value);
            }
        }
    };

    return (
        <div className="mb-3">
            {customButton ? (
                customButton(
                    filterConstants.getDisplayTextKey(selectedFilterValue),
                    filterValues?.[selectedFilterValue]
                )
            ) : title ? (
                <div className="d-flex mb-4">
                    <FilterButton
                        text={`${title}: ${filterConstants.getDisplayTextKey(selectedFilterValue)}`}
                    />
                </div>
            ) : null}
            <Collapse isOpen={isOpen}>
                {Object.keys(filterConstants).map((key, index) => {
                    return (
                        <div
                            className="d-flex"
                            key={index}
                            onClick={() => handleFilterClick(filterConstants[key])}
                        >
                            <div
                                className={`checkbox-container ${
                                    selectedFilterValue === filterConstants[key]
                                        ? "selected-checkbox"
                                        : ""
                                }`}
                            ></div>
                            <div className="d-flex justify-content-between w-100">
                                <p className="ml-2 font-weight-bold mb-1">
                                    {filterConstants.getDisplayTextKey(filterConstants[key])}
                                </p>
                                {filterValues && (
                                    <p className="font-weight-bold mb-1">
                                        {filterValues[filterConstants[key]]}
                                    </p>
                                )}
                            </div>
                        </div>
                    );
                })}
            </Collapse>
        </div>
    );
};

export default OptionFilter;
