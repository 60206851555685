import { injectBaseConstantMethods } from "./BaseConstants";

const PlanCategory = {
    Monthly: 1,
    Quarterly: 2,
    Yearly: 3
};
const displayTextKeys = {
    [PlanCategory.Monthly]: "Monthly",
    [PlanCategory.Quarterly]: "Quarterly",
    [PlanCategory.Yearly]: "Yearly"
};

const labelClass = {
    [PlanCategory.Monthly]: "",
    [PlanCategory.Quarterly]: "",
    [PlanCategory.Yearly]: ""
};

export default injectBaseConstantMethods(PlanCategory, displayTextKeys, labelClass);
