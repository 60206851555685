import { injectBaseConstantMethods } from "./BaseConstants";

export const UserSubscriptionType = {
    FREE: 1,
    PAID: 2
};

const displayTextKeys = {
    [UserSubscriptionType.FREE]: "Free",
    [UserSubscriptionType.PAID]: "Paid"
};

const labelClass = {
    [UserSubscriptionType.FREE]: "warning",
    [UserSubscriptionType.PAID]: "success"
};

export default injectBaseConstantMethods(UserSubscriptionType, displayTextKeys, labelClass);
