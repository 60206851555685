import Config from "../../Config";
import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import { toaster } from "../../utils/toasterUtil";

export const PLAN_LISTING_REQUEST = "PLAN_LISTING_REQUEST";
export const PLAN_LISTING_SUCCESS = "PLAN_LISTING_SUCCESS";
export const PLAN_LISTING_FAILED = "PLAN_LISTING_FAILED";
export const UPDATE_PAYMENT_PLAN = "UPDATE_PAYMENT_PLAN";
export const REMOVE_PAYMENT_PLAN = "REMOVE_PAYMENT_PLAN";

export const getPlanListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    dispatch({ type: PLAN_LISTING_REQUEST });

    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/plan/list", { Limit: Config.LIMIT, ...params }),
        token,
        ...(abortSignal && { abortSignal })
    })
        .then((res) => {
            dispatch({ type: PLAN_LISTING_SUCCESS, payload: res.Data });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: PLAN_LISTING_FAILED });
            return Promise.reject(err);
        });
};

export const getPaymentPlanById = (id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: `/plan/details/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const editPlan = (bodyData) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    return fetchUtil({
        url: `/plan/edit/${bodyData.Id}`,
        token,
        method: "PUT",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            dispatch({ type: UPDATE_PAYMENT_PLAN, payload: bodyData });
            toaster({ title: res.Message, type: "success" });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const createPricingPlan = (bodyData) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    return fetchUtil({
        url: `/plan/create`,
        token,
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            toaster({ title: res.Message, type: "success" });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const removePlan = (id) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: `/plan/remove/${id}`,
        token,
        method: "DELETE"
    })
        .then((res) => {
            dispatch({ type: REMOVE_PAYMENT_PLAN, id });
            toaster({ title: res.Message, type: "success" });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
