import React, { Component } from "react";
import Select from "react-select";
import Animated from "react-select/animated";

const selectStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 99
    }),
    control: (provided) => ({
        ...provided,
        border: "1px solid #ced4da",
        borderRadius: "5px",
        borderColor: "#9d9d9d",
        "&:hover": {
            borderColor: "transaparent"
        }
    })
};

class SearchableSelect extends Component {
    render() {
        let {
            className = "",
            options,
            onInputChange = undefined,
            onChange,
            customStyles = selectStyles,
            maxMenuHeight,
            isClearable = false,
            placeholder = "",
            isLoading = false,
            value,
            isMulti = false,
            handleScroll = undefined,
            handleOnFocus = undefined,
            selectRef,
            isOptionDisabled = undefined,
            closeMenuOnSelect = true,
            isDisabled = false
        } = this.props;
        return (
            <Select
                isDisabled={isDisabled}
                onFocus={handleOnFocus}
                ref={selectRef}
                onMenuScrollToBottom={handleScroll}
                className={className}
                options={options}
                onInputChange={onInputChange}
                onChange={onChange}
                styles={customStyles || selectStyles}
                maxMenuHeight={maxMenuHeight}
                isClearable={isClearable}
                placeholder={placeholder}
                closeMenuOnSelect={closeMenuOnSelect}
                isLoading={isLoading}
                value={value}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: "#556ee6"
                    }
                })}
                isOptionDisabled={isOptionDisabled}
                isMulti={isMulti}
                components={Animated()}
            />
        );
    }
}

export default SearchableSelect;
