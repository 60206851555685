export const UserPrayerRoutine = {
    REGULAR: 1,
    MODERATE: 2,
    Rarely: 3
};

export const UserPrayerRoutineLabels = {
    [UserPrayerRoutine.REGULAR]: "Regular",
    [UserPrayerRoutine.MODERATE]: "Moderate",
    [UserPrayerRoutine.Rarely]: "Rarely"
};
