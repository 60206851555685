import React from "react";
import { Button, Spinner } from "reactstrap";

const BlockButton = ({
    className = "",
    btnText,
    isLoading = false,
    color = "primary",
    loaderSize = "sm",
    children,
    onClick,
    disabled,
    type = "button",
    btn_class = "",
    ...rest
}) => {
    return (
        <Button
            className={className}
            color={color}
            onClick={onClick}
            disabled={disabled || isLoading}
            type={type}
            {...rest}
        >
            {isLoading ? (
                <Spinner size={loaderSize} />
            ) : (
                <span className={btn_class}>
                    {children}
                    {btnText}
                </span>
            )}
        </Button>
    );
};

export default BlockButton;
