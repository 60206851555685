import { injectBaseConstantMethods } from "./BaseConstants";

const MessageStatus = {
    IN_ACTIVE: 0,
    ACTIVE: 1,
    RESOLVED: 10
};

const displayTextKeys = {
    [MessageStatus.IN_ACTIVE]: "Inactive",
    [MessageStatus.ACTIVE]: "Active",
    [MessageStatus.RESOLVED]: "Resolved"
};

const labelClass = {
    [MessageStatus.IN_ACTIVE]: "danger",
    [MessageStatus.ACTIVE]: "info",
    [MessageStatus.RESOLVED]: "success"
};

export default injectBaseConstantMethods(MessageStatus, displayTextKeys, labelClass);
