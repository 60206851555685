import { fetchUtil } from "../../utils/fetchUtil";
// import { updateDeviceId } from "../../store/actions";

export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";

export const SET_TOKEN = "SET_TOKEN";

export const SIGN_OUT = "SIGN_OUT";

export const login = (bodyData) => (dispatch, getState) => {
    let deviceUUID = getState().deviceId.deviceUUID;
    const body = JSON.stringify({
        ...bodyData,
        ...(deviceUUID && { DeviceUUID: deviceUUID })
    });

    return fetchUtil({
        url: "/user/login",
        method: "POST",
        body
    })
        .then(async (res) => {
            dispatch({ type: SET_TOKEN, token: res.Data.Token });
            await dispatch(getMe(res.Data.Token));
            // dispatch(updateDeviceId(res.DeviceUUID));
            return Promise.resolve({});
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMe = (token) => (dispatch, getState) => {
    let currentToken = token || getState().userAuth.user.Token;
    dispatch({ type: ME_REQUEST });
    return fetchUtil({
        url: "/user/me",
        token: currentToken
    })
        .then((res) => {
            dispatch({ type: ME_SUCCESS, payload: res.Data });
            localStorage.isAuthenticated = true;
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: ME_ERROR });
            return Promise.reject(err);
        });
};

export const forgotPassword = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/forget-password",
        method: "POST",
        body,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const forgotPasswordVerification = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/forget-password-verification",
        method: "POST",
        body,
        token
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const resetPassword = (payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/reset-password",
        method: "POST",
        body,
        token
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
