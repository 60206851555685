export const UserMaritialStatus = {
    SINGLE: 1,
    SEPERATED: 2,
    DIVORCED: 3,
    WIDOWED: 4
};

export const MaritialStatusLabels = {
    [UserMaritialStatus.SINGLE]: "Single",
    [UserMaritialStatus.SEPERATED]: "Seperated",
    [UserMaritialStatus.DIVORCED]: "Divorced",
    [UserMaritialStatus.WIDOWED]: "Widowed"
};
