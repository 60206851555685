import React from "react";

import "./RadioButton.scss";

const RadioButton = ({
    radioName,
    label,
    labelValue,
    className,
    difference,
    labelClass,
    ...props
}) => {
    return (
        <div className={`radio-button-custom-container ${className}`}>
            <div className="custom-control custom-radio">
                <input
                    type="radio"
                    id={`${radioName}${difference}`}
                    name={radioName}
                    className="custom-control-input"
                    {...props}
                />
                <label className="custom-control-label h6" htmlFor={`${radioName}${difference}`}>
                    {label}
                </label>
            </div>
        </div>
    );
};

export default RadioButton;
