// import _ from "lodash";
import { getObjectByKeys } from "../../utils/commonUtils";

export const ENTITY_ADD_QUERIES = "ENTITY_ADD_QUERIES";
export const ENTITY_UPDATE_QUERIES = "ENTITY_UPDATE_QUERIES";

export const addCustomereQueryToEntity = (data) => (dispatch, getState) => {
    const customerQueryData = getObjectByKeys(data, "ContactId");
    dispatch({ type: ENTITY_ADD_QUERIES, payload: customerQueryData });
};

export const updateCustomerQueryInEntity = (id, data) => (dispatch, getState) => {
    const existingData = getState().entities.queries;

    if (existingData[id]) {
        dispatch({ type: ENTITY_UPDATE_QUERIES, id, data });
    }
};
