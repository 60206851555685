import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Row, Col, CardTitle, FormGroup, Spinner } from "reactstrap";

import "../DashboardFilter.scss";
import OptionFilter from "../OptionFilter";
import RangeFilter from "../RangeFilter";
import SelectFilter from "../SelectFilter";
import { GenderType, PlanCategory, DashboardUserFilterTypes } from "../../../constants";
import { SplineChart } from "../../../components/Chart";
import { FilterButton } from "../../../components/FilterButton";
import { RangePicker } from "../../../components/RangePicker";
import { getCountryListing, getMatchesAnalytics } from "../../../store/actions";
import { Checkbox } from "../../../components/Checkbox";
import { SearchableSelect } from "../../../components/InputSelect";
import { useDispatch } from "react-redux";
import { formatGraphLabels } from "../../../utils/commonUtils";

const USER_FILTERS = {
    USERS: { label: "Users", apiKey: "User" },
    GENDER: { label: "Gender", apiKey: "Gender" },
    AGE: { label: "Age", apiKey: "Age" },
    LOCATION: { label: "Location", apiKey: "Location" },
    PAYMENT_PLAN: { label: "Payment Plan", apiKey: "Plan" }
};

const DashboardMatchFilter = (props) => {
    const {
        filters,
        commonFilters,
        isFilterOpen,
        setIsFilterOpen,
        prepareUserFilterValues
    } = props;
    const [graphData, setGraphData] = useState(null);
    const [enableCompare, setEnableCompare] = useState(false);
    const [selectedFilterToCompare, setSelectedFilterToCompare] = useState(null);
    const [comparedFilterValue, setComparedFilterValue] = useState(null);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        comparedStartDate: null,
        comparedEndDate: null
    });
    const dispatch = useDispatch();
    const userAnalyticsAbortController = useRef();

    useEffect(() => {
        userAnalyticsAbortController.current = new AbortController();
        dispatch(
            getMatchesAnalytics(
                {
                    Filter1: {
                        UserType: filters.user,
                        ...(dateRange.startDate &&
                            dateRange.endDate && {
                                DateRange: {
                                    StartDate: dateRange.startDate.format("YYYY-MM-DD"),
                                    EndDate: dateRange.endDate.format("YYYY-MM-DD")
                                }
                            })
                    }
                },
                userAnalyticsAbortController.current.signal
            )
        )
            .then((res) => {
                if (res && res.MatchAnalytics1) {
                    setGraphData({
                        data: Object.values(res.MatchAnalytics1).map((data) => data.TotalMatches),
                        categories: formatGraphLabels(Object.keys(res.MatchAnalytics1), res.Format)
                    });
                }
            })
            .catch((err) => {});

        return () => {
            if (userAnalyticsAbortController.current) {
                userAnalyticsAbortController.current.abort();
            }
        };
    }, [dispatch, filters, dateRange]);

    const handleFilterOpenClose = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const getFilterOptions = () => {
        return Object.values(USER_FILTERS).map((item) => ({
            label: item.label,
            value: item
        }));
    };

    const handleCompareToggle = () => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
        } else {
            if (
                dateRange.startDate ||
                dateRange.endDate ||
                dateRange.comparedStartDate ||
                dateRange.comparedEndDate
            ) {
                setDateRange({
                    startDate: null,
                    endDate: null,
                    comparedStartDate: null,
                    comparedEndDate: null
                });
            }
        }
        setEnableCompare((prev) => !prev);
    };

    const handleTimeRangeApplied = (val) => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
            setEnableCompare(false);
        }
        setDateRange(val);
    };

    const renderUserFilter = () => {
        return (
            <Col md="2">
                <OptionFilter
                    title="Select User"
                    isOpen={true}
                    filterConstants={DashboardUserFilterTypes}
                    selectedFilterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val)}
                    filterValues={prepareUserFilterValues()}
                    required={true}
                />
            </Col>
        );
    };

    const renderGenderFilter = () => {
        return (
            <Col md="2">
                <OptionFilter
                    title="Select Gender"
                    isOpen={true}
                    filterConstants={GenderType}
                    selectedFilterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val)}
                />
            </Col>
        );
    };

    const renderAgeFilter = () => {
        return (
            <Col md="2">
                <RangeFilter
                    title="Select Age"
                    min={18}
                    max={60}
                    minValue={comparedFilterValue?.minAge}
                    maxValue={comparedFilterValue?.maxAge}
                    isOpen={true}
                    onChange={(minVal, maxVal) =>
                        setComparedFilterValue({ minAge: minVal, maxAge: maxVal })
                    }
                />
            </Col>
        );
    };

    const renderLocationFilter = () => {
        return (
            <Col md="2">
                <SelectFilter
                    title="Select Location"
                    requestAction={getCountryListing}
                    isOpen={true}
                    placeholder="Select Location"
                    filterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val || [])}
                    getOptions={(data) =>
                        data.map((item) => ({
                            label: item.Name,
                            value: item
                        }))
                    }
                />
            </Col>
        );
    };

    const renderPaymentPlanFilter = () => {
        return (
            <Col md="2">
                <OptionFilter
                    title="Select Payment Plan"
                    isOpen={true}
                    filterConstants={PlanCategory}
                    selectedFilterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val)}
                />
            </Col>
        );
    };

    const renderComparedFilters = () => {
        let comparedFilter = null;

        switch (selectedFilterToCompare?.label) {
            case USER_FILTERS.USERS.label:
                comparedFilter = renderUserFilter();
                break;
            case USER_FILTERS.GENDER.label:
                comparedFilter = renderGenderFilter();
                break;
            case USER_FILTERS.AGE.label:
                comparedFilter = renderAgeFilter();
                break;
            case USER_FILTERS.LOCATION.label:
                comparedFilter = renderLocationFilter();
                break;
            case USER_FILTERS.PAYMENT_PLAN.label:
                comparedFilter = renderPaymentPlanFilter();
                break;
            default:
                comparedFilter = null;
                break;
        }
        return comparedFilter;
    };

    return (
        <div>
            <Row className="pb-4">
                {commonFilters}
                <Col md="2">
                    <div className="d-flex align-items-center">
                        <FilterButton text="Filter" onClick={handleFilterOpenClose} />
                        <i
                            onClick={handleFilterOpenClose}
                            className={`cursor-pointer bx bx-chevron-${
                                isFilterOpen ? "down" : "right"
                            } chevron-icon`}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md="2">
                    <FormGroup className="my-2">
                        <Checkbox
                            title="Compare with:"
                            checked={enableCompare}
                            onChange={handleCompareToggle}
                            id="enable-copare"
                        />
                    </FormGroup>
                </Col>
                {enableCompare && (
                    <Col md="2">
                        <SearchableSelect
                            options={getFilterOptions()}
                            placeholder="Select Filter"
                            isClearable
                            value={selectedFilterToCompare}
                            onChange={(val) => {
                                setComparedFilterValue(null);
                                setSelectedFilterToCompare(val);
                            }}
                        />
                    </Col>
                )}
                {renderComparedFilters()}
            </Row>
            <div className="filter-main-container" />
            <Row>
                <Col md="12">
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <p>From 2020 Till 2022</p>
                        <div className="d-flex">
                            <RangePicker value={dateRange} onChange={handleTimeRangeApplied} />
                            <FilterButton text="Export" classes="font-weight-bold" />
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <Card className="mt-3">
                        <CardBody>
                            <CardTitle className="mb-4"> Total Matches </CardTitle>
                            {graphData ? (
                                <SplineChart
                                    graph1Title="Matches"
                                    data={graphData?.data}
                                    categories={graphData?.categories}
                                />
                            ) : (
                                <div className="d-flex align-items-center justify-content-center dashboard-graph-height">
                                    <Spinner size="md" color="primary" />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DashboardMatchFilter;
