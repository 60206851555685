import { fetchUtil } from "../../utils/fetchUtil";

export const getCountryListing = () => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/common/countries",
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
