import { injectBaseConstantMethods } from "./BaseConstants";

const DiscountType = {
    NONE: 0,
    FIXED: 1,
    PERCENTAGE: 2
};

const displayTextKeys = {
    [DiscountType.NONE]: "None",
    [DiscountType.FIXED]: "Fixed",
    [DiscountType.PERCENTAGE]: "Percentage"
};

const labelClass = {
    [DiscountType.NONE]: "danger",
    [DiscountType.FIXED]: "success",
    [DiscountType.PERCENTAGE]: "info"
};

export default injectBaseConstantMethods(DiscountType, displayTextKeys, labelClass);
